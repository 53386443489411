
import React, { useState, useEffect } from 'react';
import API from '../../utils/apiCalling'
import { config } from '../../utils/apiUrl';
import { ToastContainer, toast } from 'react-toastify'
// import ModalEditBlog from './modal';

import { CCollapse } from '@coreui/react'
import AddQuizQuestion from './addQuizQuestion';
const GetQuiz = (props) => {
    const { allQuiz } = props
    const api = new API()
    
    // const [active, setActive] = useState(0)
    // const [blogForEdit, setBlogForEdit] = useState()
    // const [modal, setModal] = useState(false)
    const [isAddQuizQuestion, setIsAddQuizQuestion] = useState(false)
    const [selectedQuiz, setSelectedQuiz] = useState()

    const [imageUrl, setImageUrl] = useState("http://localhost:5000/quiz_image/quiz_image")
    useEffect(() => {
 
    }, [])
  
    const addQuizQuestionFun = (quiz) =>{
        setIsAddQuizQuestion(!isAddQuizQuestion)
        setSelectedQuiz(quiz)
    }

    // const deleteBlog = async (blog) => {
    //     let data = {
    //         blog: blog._id
    //     }
    //     console.log("blog id is", data)
    //     swal({
    //         html: true,
    //         title: "Thank You!,",
    //         text: `Are you sure to delete blog`,
    //         icon: "success",
    //         buttons: ["No, cancel it!", "Delete"],
    //         dangerMode: true,
    //     }).then(async function (isConfirm) {
    //         if (isConfirm) {
    //             let result = await api.delete(config.deleteBlog, `${data.blog}`)
    //             console.log("getting blog", result)
    //             if (result) {

    //                 toast.success(`Hi, Thanks for add blog!`)
    //                 getAllBlog()
    //             } else {
    //                 console.log("something went wrong")
    //             }
    //         }
    //     })
    // }

    // const editBlog = (blog) => {
    //     swal({
    //         html: true,
    //         title: "Thank You!,",
    //         text: `Are you sure to edit blog`,
    //         icon: "success",
    //         buttons: ["No, cancel it!", "Edit"],
    //         dangerMode: true,
    //     }).then(async function (isConfirm) {
    //         if (isConfirm) {

    //             let result = await api.put(config.updateBlog, blog)
    //             if (result) {
    //                 console.log("edit blog is", result)
    //                 toast.success(`Hi, Thanks for edit blog!`)
    //                 getAllBlog()
    //                 setModal(!modal)
    //             }
    //             else {
    //                 toast.success(`Hi, Something went wrong!`)
    //             }
    //         }
    //     }
    //     )
    //     alert("blog for edit")
    // }

    // const toggle = (blog) => {
    //     setBlogForEdit(blog)
    //     setModal(!modal)
    // }

    // const blogActiveInactive = (blog) => {
    //     let status = blog.status == "active" ? "inactive" : "active";
    //     let data = {
    //         id: blog._id,
    //         status: status
    //     }
    //     console.log(data)
    //     swal({
    //         html: true,
    //         title: "Thank You!,",
    //         text: `Are you sure to ${status} blog`,
    //         icon: "success",
    //         buttons: ["No, cancel it!", "Submit"],
    //         dangerMode: true,
    //     }).then(async function (isConfirm) {
    //         if (isConfirm) {
    //             let result = await api.post(config.blogStatusUpdate, data)
    //             if (result && result.code == 200) {
    //                 console.log("edit blog is", result)
    //                 toast.success(result.message)
    //                 getAllBlog()
    //             }
    //             else {
    //                 toast.success(result && result.code == 200)
    //             }
    //         }
    //     }
    //     )
    // }
    return (

        <div style={{ margin: "auto" }}>
                {isAddQuizQuestion ?
                <AddQuizQuestion selectedQuiz={selectedQuiz} setIsAddQuizQuestion={setIsAddQuizQuestion}/>
                :
            <div style={{ margin: "auto", height: "auto", overflow: "auto" }}>
                <div className="row">
                    {allQuiz && allQuiz.length>0 && allQuiz.map((quiz, index)=>(
                        <div className="col-md-6">
                            <div  className="card mb-2">
                                <div className="card-header">
                                    <span>{quiz.quiz_name}</span>
                                    <button className="btn btn-info float-right" >
                                      {quiz.status}
                                    </button>
                                </div>
                                <div className="card-body">
                                    <form>
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">{quiz.type}</label>
                                            <input value="type" disabled className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Quiz Tag</label>
                                            <input type="text" value="tag" disabled className="form-control" />
                                        </div>
                                        {/* <CCollapse show={active}> */}
                                            <>
                                                {/* <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">Blog sub heading</label>
                                                    <input type="text" value={blog.blog_sub_heading} disabled className="form-control" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">Blog blog_content</label>
                                                    <textarea value={blog.blog_content} disabled className="form-control" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">Blog blog_sub_content_1</label>
                                                    <textarea value={blog.blog_sub_content_1} disabled className="form-control" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">Blog blog_sub_content_2</label>
                                                    <textarea value={blog.blog_sub_content_2} disabled className="form-control" />
                                                </div> */}
                                            </>
                                        {/* </CCollapse> */}
                                        <div className="row text-center">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">Quiz image</label><br></br>
                                                    <img src={`${imageUrl}/${quiz && quiz.quizImage}`} alt="..." height={150} width="350" />
                                                </div>
                                            </div>
                                            {/* <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">Blog sub image</label><br></br>
                                                    <img src={`${imageUrl}/${blog && blog.image2}`} alt="..." height={150} />
                                                </div>
                                            </div> */}
                                        </div>
                                    </form>
                                </div>
                                <div className="card-footer">
                                    <button type="submit"  className="btn btn-primary">Edit</button>
                                    <button style={{ marginLeft: "5px" }}  type="submit" className="btn btn-danger">Delete</button>
                                    <button style={{ marginLeft: "5px" }} onClick={()=>addQuizQuestionFun(quiz)}  type="submit" className="btn btn-danger">Add Quiz Question</button>
                                    
                                    
                                    <button  type="submit" className="btn btn-info float-right">{true ? <i class="fa fa-arrow-up" aria-hidden="true"></i> : <i class="fa fa-arrow-down" aria-hidden="true"></i>}</button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
}

            {/* <ModalEditBlog modal={modal} toggle={toggle}
                editBlog={editBlog}
                blogForEdit={blogForEdit} /> */}
        </div>



    );
}

export default GetQuiz;