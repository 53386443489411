
import React, { useState, useEffect } from 'react';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import API from '../../../utils/apiCalling'
import { config } from '../../../utils/apiUrl'
import { validation } from '../../../utils/configCommon';
import { setLocalDataAsObject, getLocalDataAsObject } from '../../../utils/CoustomStorage';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Collapse from 'react-bootstrap/Collapse'
import swal from "sweetalert";
import GetGkQuestionAnswer from './get_gk';
import ModalEdit from './modal';
import { gkCategoryTypes, gkType, stateGkType, other } from 'static/static';
import Select from 'react-select';
import { MultiSelect } from "react-multi-select-component";

const options = [
    { label: "current gk 🍇", value: "current gk" },
    { label: "India gk 🥭", value: "India gk" },
    { label: "Bollywood gk 🍓", value: "Bollywood gk", disabled: true },
    { label: "science gk 🍇", value: "grapes" },
    { label: "Mango 🥭", value: "mango" },
    { label: "Strawberry 🍓", value: "strawberry", disabled: true },
    { label: "Grapes 🍇", value: "grapes" },
    { label: "Mango 🥭", value: "mango" },
    { label: "Strawberry 🍓", value: "strawberry", disabled: true },
];

const AddGkQuestion = (props) => {
    const { } = props
    let api = new API()
    let history = useHistory();
    const [selectedGkType, setSelectedGkType] = useState("")
    const [open, setOpen] = useState(true)
    const [getAllQuestions, setGetAllQuestions] = useState()
    const [questionForEdit, setQuestionForEdit] = useState()
    const [modal, setModal] = useState(false)
    const [subCategoryType, setSubCategoryType] = useState([])
    const [question, setQuestion] = useState({
        question: "",
        answer: ""
    })
    const [selected, setSelected] = useState([]);
    const [allNavItem, setAllNavItem] = useState([]);
    const [selectedSubCategory, setSelectedSubCategory] = useState()
    const [selectedSubCategoryAdditionalCat, setSelectedSubCategoryAdditionalCat] = useState([])

    useEffect(() => {
        getAllGkQuestion()
    }, [selectedGkType])

    useEffect(() => {
        getNavItem()
    }, [])

    const notify = (message) => toast(message);

    const onChangeFun = (e) => {
        const { name, value } = e.target
        setQuestion({
            ...question,
            [name]: value
        })
    }
    const getNavItem = async () => {
        let data = {
            type: "question_answer"
        }
        // console.log("data is", data)
        let result = await api.get(config.getNavItem, true, data)
        console.log("getting all NavItem", result)
        if (result) {
            setAllNavItem(result.data[0].data)
            console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", result.data[0].data)
        } else {
            console.log(result && result.message)
        }
    }

    const getAllGkQuestion = async () => {
        let data = {
            gkType: selectedGkType ? selectedGkType : "currentGK"
        }
        console.log("data is", data)
        let result = await api.post(config.getQuestion, data)
        console.log("getting all Questions", result)
        if (result) {
            // console.log("getting blog data", result)
            setGetAllQuestions(result)
        } else {
            console.log("something went wrong")
        }
    }
    const addQuestions = (e) => {
        swal({
            html: true,
            title: "Thank You!,",
            text: `Are you sure to add question`,
            icon: "success",
            buttons: ["No, cancel it!", "Submit"],
            dangerMode: true,
        }).then(async function (isConfirm) {
            if (isConfirm) {
                let data = {
                    gktype: selectedGkType,
                    question: question.question,
                    answer: question.answer,
                    additionalCategory : JSON.stringify(selected)
                }
                let result = await api.post(config.addQuestion, data)
                if (result) {
                    console.log("questio added ", result)
                    toast.success(`Hi, Thanks for add question!`)
                    getAllGkQuestion()
                    reset()
                }
            }
        }
        )
    }

    const reset = () => {
        setQuestion({
            question: "",
            answer: "",
        })
        setSelected([])
    }
    const selectGkTypeFun = (e) => {
        setSelectedGkType(e.target.value)
    }
    const deleteQuestion = async (que) => {
        let data = {
            id: que._id,
            gktype: selectedGkType
        }
        swal({
            html: true,
            title: "Thank You!,",
            text: `Are you sure to delete question`,
            icon: "success",
            buttons: ["No, cancel it!", "Delete"],
            dangerMode: true,
        }).then(async function (isConfirm) {
            if (isConfirm) {
                let result = await api.delete(config.delete_Question, `${data.id}/${data.gktype}`)
                if (result) {
                    console.log("delete questio is", result)
                    toast.success(`Hi, Thanks for delete question!`)

                    getAllGkQuestion()
                } else {
                    console.log("unable to delete")
                }
            }
        })
    }
    const editQuestion = async (que) => {
        let data = {
            _id: questionForEdit._id,
            question: que.question,
            answer: que.answer,
            gktype: selectedGkType,
            additionalCategory : JSON.stringify(selected)
        }
        swal({
            html: true,
            title: "Thank You!,",
            text: `Are you sure to edit this question`,
            icon: "success",
            buttons: ["No, cancel it!", "Edit"],
            dangerMode: true,
        }).then(async function (isConfirm) {
            if (isConfirm) {
                let result = await api.put(config.updateQuestion, data)
                if (result) {
                    console.log("question updated successfully", data)
                    toast.success(`Hi, Thanks for update question!`)
                    setModal(!modal)
                    getAllGkQuestion()
                }

            }
        })
    }
    const toggle = (que) => {
        setQuestionForEdit(que)
        setModal(!modal)
    }

    const statusActiveInactive = (que) => {
        let status = que.status == "active" ? "inactive" : "active";
        let data = {
            id: que._id,
            gktype: selectedGkType,
            status: status
        }
        console.log(data)
        swal({
            html: true,
            title: "Thank You!,",
            text: `Are you sure to ${status} question`,
            icon: "success",
            buttons: ["No, cancel it!", "Submit"],
            dangerMode: true,
        }).then(async function (isConfirm) {
            if (isConfirm) {
                let result = await api.post(config.statusChange, data)
                if (result && result.code == 200) {
                    console.log("edit blog is", result)
                    toast.success(result.message)
                    getAllGkQuestion()
                }
                else {
                    toast.success(result && result.code == 200)
                }
            }
        }
        )
    }
    const selectGkCategoryType = (e) => {

        setSelectedSubCategory(allNavItem[e.target.value])

    }

    const selectGkCategoryTypeForAdditionalCategory = (e) => {
        // console.log(allNavItem[e.target.value])
        setSelectedSubCategoryAdditionalCat(allNavItem[e.target.value].subCategory)

    }

    const deleteAdditionalCat = (delValue) =>{
        let newSelected = selected.filter(val=> delValue != val.value)
        setSelected(newSelected)
    }
    return (
        <div className="container-fluid mt-3">
            <button className="btn btn-primary" onClick={() => setOpen(!open)}>Add GK</button>
            <Collapse in={open}>
                <div className="card">
                    <div className="card-header">
                        Add general Knowledge..
                    </div>
                    <div className="card-body">
                        <div className="row" style={{ marginTop: "1%" }}>
                            <div className="col-md-12">
                                <div className="container">

                                    <div className='mb-2'>
                                        <select className="form-control" onChange={selectGkCategoryType}>
                                            <option value="">Add GK For</option>
                                            {allNavItem.length > 0 && allNavItem.map((navItem, index) => (
                                                <option key={index} value={index}>{navItem.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <select className="form-control" onChange={selectGkTypeFun}>
                                        <option value="">Select Gk Type</option>
                                        {selectedSubCategory && selectedSubCategory.subCategory && selectedSubCategory.subCategory.length > 0 && selectedSubCategory.subCategory.map((subCat, ind) => (
                                            <option key={ind} value={subCat.value}>{subCat.name}</option>
                                        ))}
                                    </select>


                                    {/* <select className="form-control" onChange={selectGkTypeFun}>
                                        <option value="">Select Gk Type</option>
                                        {subCategoryType && subCategoryType.length > 0 && subCategoryType.map((gktype, index) => (
                                        
                                                <option key={index} value={gktype.value}>{gktype.name}</option>
                                            ))}
                                     </select> */}
                                    {/* <select className="form-control" onChange={selectGkCategoryType}>
                                        <option value="">Add GK For</option>
                                        {gkCategoryTypes && gkCategoryTypes.length > 0 && gkCategoryTypes.map((gktype, index) => (
                                            <option key={index} value={gktype.value}>{gktype.name}</option>
                                        ))}
                                    </select>

                                    <select className="form-control" onChange={selectGkTypeFun}>
                                        <option value="">Select Gk Type</option>
                                        {subCategoryType && subCategoryType.length > 0 && subCategoryType.map((gktype, index) => (
                                            <option key={index} value={gktype.value}>{gktype.name}</option>
                                        ))}
                                    </select> */}

                                    {/* <Select
                                        defaultValue={selectedOption}
                                        onChange={setSelectedOption}
                                        options={options}
                                    /> */}


                                    {selectedGkType &&
                                        <>
                                            <h5 align="center" className="mt-3">{selectedGkType} General Knowledge </h5>
                                            <AvForm onValidSubmit={addQuestions} className="row">
                                                <div className="col-lg-12">
                                                    {/* <h4 className="text-center mb-0 uppercase">Fill details for add question</h4> */}
                                                </div>


                                                <div className="col-lg-12 input-group custom mb-0">
                                                    <div className="col-lg-12 custom mb-0">
                                                        {/* <span className="input-group-text bg-transparent  border border-0 text-white">
        <i className="fa fa-mobile" aria-hidden="true" /> &nbsp; */}
                                                        <span>Question</span>
                                                        {/* </span> */}

                                                        <AvField className="form-control bg-transparent" type="text" name="question"
                                                            value={question.question} onChange={onChangeFun}
                                                            validate={{
                                                                required: {
                                                                    value: true,
                                                                    errorMessage: "Please Enter Question",
                                                                },
                                                                minLength: {
                                                                    value: 1,
                                                                    errorMessage: "Question Lenght should be more than 10",
                                                                },
                                                                maxLength: {
                                                                    value: 1000,
                                                                    errorMessage: "Question Lenght more than limit",
                                                                },
                                                            }}
                                                            placeholder="Enter Question..." />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 input-group custom mb-0">
                                                    <div className="col-lg-12 custom mb-0">
                                                        {/* <span className="input-group-text bg-transparent  border border-0 text-white">
        <i className="fa fa-mobile" aria-hidden="true" /> &nbsp; */}
                                                        <span>Answer</span>
                                                        {/* </span> */}

                                                        <AvField className="form-control bg-transparent" type="text" name="answer"
                                                            value={question.answer} onChange={onChangeFun}
                                                            validate={{
                                                                required: {
                                                                    value: true,
                                                                    errorMessage: "Please Enter Answer",
                                                                },
                                                                minLength: {
                                                                    value: 1,
                                                                    errorMessage: "Answer Lenght should be more than 10",
                                                                },
                                                                maxLength: {
                                                                    value: 1000,
                                                                    errorMessage: "Answer Lenght more than limit",
                                                                },
                                                            }}
                                                            placeholder="Enter Answer..." />


                                                    </div>
                                                </div>

                                                <div className="col-lg-12 input-group custom mb-0">
                                                    <div className="col-lg-12 custom mb-0">
                                                        {/* <span className="input-group-text bg-transparent  border border-0 text-white">
        <i className="fa fa-mobile" aria-hidden="true" /> &nbsp; */}
                                                        <span>Select Additional Category</span>
                                                        {/* </span> */}

                                                        <div className='row'>
                                                            <div className='col-md-6'>
                                                                <div className='mb-2'>
                                                                    <select className="form-control" onChange={selectGkCategoryTypeForAdditionalCategory}>
                                                                        <option value="">Add GK For</option>
                                                                        {allNavItem.length > 0 && allNavItem.map((navItem, index) => (
                                                                            <option key={index} value={index}>{navItem.name}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>

                                                                <MultiSelect
                                                                    options={selectedSubCategoryAdditionalCat.map(val => {
                                                                        val.label = val.name
                                                                        return val
                                                                    })}
                                                                    value={selected}
                                                                    onChange={setSelected}
                                                                    labelledBy="Select"
                                                                />
                                                            </div>
                                                            <div className='col-md-6'>
                                                                {selected.length > 0 && selected.map(val => (
                                                                    <button className='btn btn-info btn-small rounded-pill p-2 mr-1'>{val.name} <i onClick={()=>deleteAdditionalCat(val.value)} style={{cursor:"pointer"}} class="fa fa-trash" aria-hidden="true"></i></button>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-12 text-center text-center mt-3">
                                                    <button type="submit" className="btn btn-success btn-block rounded-0 button-room" >
                                                        <i className="fa fa-plus" aria-hidden="true" /> Add Question </button>
                                                </div>
                                            </AvForm>

                                        </>
                                    }

                                    <div>


                                    </div>
                                </div>
                            </div>

                        </div>
                        <ToastContainer align={"right"} />
                    </div>
                </div>
            </Collapse>
            <GetGkQuestionAnswer
                getAllQuestions={getAllQuestions}
                deleteQuestion={deleteQuestion} toggle={toggle}
                selectedGkType={selectedGkType}
                statusActiveInactive={statusActiveInactive} />
            <ModalEdit
                modal={modal}
                toggle={toggle}
                editQuestion={editQuestion}
                questionForEdit={questionForEdit}
                allNavItem={allNavItem} selectGkCategoryTypeForAdditionalCategory={selectGkCategoryTypeForAdditionalCategory} selectedSubCategoryAdditionalCat={selectedSubCategoryAdditionalCat} selected={selected} 
                setSelected={setSelected}
                deleteAdditionalCat={deleteAdditionalCat} />
            <ToastContainer />
        </div>
    );
}

export default AddGkQuestion;