import Config from '../config';

export const config = {
    getGk: `${Config.site_api}/dashboard/getGeneralknowledge`,
    getBlog: `${Config.site_api}/dashboard/getBlog`,


    // get Multiple choice question

    getMultipleChoiceQue: `${Config.site_api}/dashboard/multipleChoiceQuestion`,

    // Quiz
    exerciseParticipant: `${Config.site_api}/dashboard/exerciseParticipant`,
    query: `${Config.site_api}/dashboard/query`,

    // Admin Dashboard
    addQuestion: `${Config.site_api}/admin-dashboard/addQuestion`,
    delete_Question: `${Config.site_api}/admin-dashboard/deleteQuestion`,
    updateQuestion: `${Config.site_api}/admin-dashboard/updateQuestion`,
    getQuestion: `${Config.site_api}/admin-dashboard/getQuestion`,
    statusChange: `${Config.site_api}/admin-dashboard/statusChange`,
    login: `${Config.site_api}/admin/login`,
    signup: `${Config.site_api}/admin/signup`,

    token_verify: `${Config.site_api}/admin/token_verify`,
    getBlogAdminPanel: `${Config.site_api}/admin-dashboard/getBlogAdminPanel`,
    addBlog: `${Config.site_api}/admin-dashboard/addBlog`,
    deleteBlog: `${Config.site_api}/admin-dashboard/deleteBlog`,
    updateBlog: `${Config.site_api}/admin-dashboard/updateBlog`,
    blogStatusUpdate: `${Config.site_api}/admin-dashboard/blogStatusUpdate`,
    getParticipant: `${Config.site_api}/admin-dashboard/getParticipant`,
    getQuery: `${Config.site_api}/admin-dashboard/getQuery`,
    // SEO
    getAllUrlItem: `${Config.site_api}/admin-dashboard/seo/getAllUrlItem`,  //get
    addSeo : `${Config.site_api}/admin-dashboard/seo/addSeo`,  //post 
    getAddedSeo : `${Config.site_api}/admin-dashboard/seo/getAddedSeo`,  //get 
    deleteSeo : `${Config.site_api}/admin-dashboard/seo/deleteSeo`,  //get 
    
    // Token Verify

    // multipleChoice Gk
    getQuestionMultipleChoice: `${Config.site_api}/admin-dashboard/getQuestionMultipleChoice`,  //post
    addQuestionMultipleChoice: `${Config.site_api}/admin-dashboard/addQuestionMultipleChoice`,    //post
    updateQuestionMultipleChoice: `${Config.site_api}/admin-dashboard/updateQuestionMultipleChoice`,   //put
    deleteQuestionMultipleChoice: `${Config.site_api}/admin-dashboard/deleteQuestionMultipleChoice`,  //delete
    statusChangeMultipleChoice: `${Config.site_api}/admin-dashboard/statusChangeMultipleChoice`,  //status change
    // User
    addUser: `${Config.site_api}/api/user/addUser`,  //delete
    getUser: `${Config.site_api}/api/user/getUser`,  //get user
    deleteUser: `${Config.site_api}/api/user/deleteUser`,  //delete
    updateUser: `${Config.site_api}/api/user/updateUser`,  //delete
    userStatusActiveInactive: `${Config.site_api}/api/user/userStatusActiveInactive`,  //delete

    // quiz

    addQuiz: `${Config.site_api}/api/quiz/addQuiz`,  //delete
    getQuiz: `${Config.site_api}/api/quiz/getQuiz`,  //get
    addQuizQuestion: `${Config.site_api}/api/quiz/addQuizQuestion`,  //post
    getQuizQuestion: `${Config.site_api}/api/quiz/getQuizQuestion`,  //post

    getNavItem: `${Config.site_api}/api/common/getNavItem`,  //get
}
