
import React, { useState, useEffect } from 'react';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import API from '../../../utils/apiCalling'
import { config } from '../../../utils/apiUrl'
import { validation } from '../../../utils/configCommon';
import { setLocalDataAsObject, getLocalDataAsObject } from '../../../utils/CoustomStorage';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Collapse from 'react-bootstrap/Collapse'
import swal from "sweetalert";
import GetGkMultipleChoiceQuestion from './get_gk';
import ModalEditMultipleChoiceQue from './modal';
import { gkCategoryTypesMCQ, gkTypeMCQ, stateGkTypeMCQ, otherMCQ } from 'static/static';
import Select from 'react-select';
import { MultiSelect } from "react-multi-select-component";

const AddMultipleChoiceQue = (props) => {
    const { } = props
    let api = new API()
    let history = useHistory();
    const [selectedGkType, setSelectedGkType] = useState("")
    const [open, setOpen] = useState(true)
    const [getAllQuestions, setGetAllQuestions] = useState([])
    const [questionForEdit, setQuestionForEdit] = useState()
    const [modal, setModal] = useState(false)
    const [addForgkType, setAddForgkType] = useState("")
    const [subCategoryType, setSubCategoryType] = useState([])
    const [questionOptions, setQuestionOptions] = useState([])

    const [question, setQuestion] = useState({
        status: "inactive",
        question_type: "radio",
        question: "",
        option: "",
        answer: "",
    })
    const [selected, setSelected] = useState([]);
    const [allNavItem, setAllNavItem] = useState([]);
    const [selectedSubCategory, setSelectedSubCategory] = useState()
    const [selectedSubCategoryAdditionalCat, setSelectedSubCategoryAdditionalCat] = useState([])

    useEffect(() => {
        getAllGkQuestion()
    }, [selectedGkType])

    useEffect(() => {
        getNavItem()
    }, [])
    const notify = (message) => toast(message);

    const getNavItem = async () => {
        let data = {
            type: "question_answer"
        }
        // console.log("data is", data)
        let result = await api.get(config.getNavItem, true, data)
        console.log("getting all NavItem", result)
        if (result) {
            setAllNavItem(result.data[1].data)
            console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", result.data[0].data)
        } else {
            console.log(result && result.message)
        }
    }
    const getAllGkQuestion = async () => {
        let data = {
            type: selectedGkType
        }
        console.log("data is", data)
        let result = await api.post(config.getQuestionMultipleChoice, data)
        console.log("getting all Questions", result)
        if (result) {
            console.log("getting gk data", result)
            setGetAllQuestions(result)
        } else {
            console.log("something went wrong")
        }
    }
    const addQuestions = (e) => {
        if (questionOptions.length < 2) {
            notify("Please enter atleast two option")
            return
        }
        let data = question
        data.options = JSON.stringify(questionOptions)
        data.gktype = selectedGkType
        data.additionalCategory = JSON.stringify(selected)
        console.log(data)
        // return
        swal({
            html: true,
            title: "Thank You!,",
            text: `Are you sure to add question`,
            icon: "success",
            buttons: ["No, cancel it!", "Submit"],
            dangerMode: true,
        }).then(async function (isConfirm) {
            if (isConfirm) {

                let result = await api.post(config.addQuestionMultipleChoice, data)
                console.log(result)
                if (result) {
                    console.log("questio added ", result)
                    toast.success(`Hi, Thanks for add question!`)
                    getAllGkQuestion()
                    reset()
                }
            }
        }
        )
    }

    const reset = () => {
        setQuestion({
            status: "active",
            question_type: "radio",
            status: "inactive",
            question: "",
            option: "",
            answer: "",
        })
        setSelected([])
        setQuestionOptions([])
    }

    const selectGkTypeFun = (e) => {
        setSelectedGkType(e.target.value)
    }
    const deleteQuestion = async (que) => {
        let data = {
            id: que._id,
            gktype: selectedGkType
        }
        swal({
            html: true,
            title: "Thank You!,",
            text: `Are you sure to delete question`,
            icon: "success",
            buttons: ["No, cancel it!", "Delete"],
            dangerMode: true,
        }).then(async function (isConfirm) {
            if (isConfirm) {
                let result = await api.delete(config.deleteQuestionMultipleChoice, `${data.id}/${data.gktype}`)
                if (result) {
                    console.log("delete questio is", result)
                    toast.success(`Hi, Thanks for delete question!`)

                    getAllGkQuestion()
                } else {
                    console.log("unable to delete")
                }
            }
        })
    }
    const editQuestion = async (que) => {
        let data = que
        // data._id = questionForEdit._id,
        data.gktype = selectedGkType
        // console.log(que.options)
        data.options = que.options
        data.additionalCategory = JSON.stringify(selected)
        // return
        swal({
            html: true,
            title: "Thank You!,",
            text: `Are you sure to edit this question`,
            icon: "success",
            buttons: ["No, cancel it!", "Edit"],
            dangerMode: true,
        }).then(async function (isConfirm) {
            if (isConfirm) {
                let result = await api.put(config.updateQuestionMultipleChoice, data)
                if (result && result.code == 200) {
                    console.log("question updated successfully..", result)
                    // toast.success(`Hi, Thanks for update question!`)
                    setModal(false)
                    getAllGkQuestion()
                }
            }
        })
    }
    const toggle = (que) => {
        setQuestionForEdit(que)
        setModal(!modal)
    }
    const statusActiveInactive = (que) => {
        let status = que.status == "active" ? "inactive" : "active";
        let data = {
            id: que._id,
            gktype: selectedGkType,
            status: status
        }
        console.log(data)
        swal({
            html: true,
            title: "Thank You!,",
            text: `Are you sure to ${status} question`,
            icon: "success",
            buttons: ["No, cancel it!", "Submit"],
            dangerMode: true,
        }).then(async function (isConfirm) {
            if (isConfirm) {
                let result = await api.post(config.statusChangeMultipleChoice, data)
                if (result && result.code == 200) {
                    console.log("edit blog is", result)
                    toast.success(result.message)
                    getAllGkQuestion()
                }
                else {
                    toast.success(result && result.code == 200)
                }
            }
        }
        )
    }
    const selectGkCategoryType = (e) => {
        setSelectedSubCategory(allNavItem[e.target.value])
    }
    const addingOption = () => {
        if (!question.option) {
            notify("Please enter option")
            return
        }
        console.log("inside adding oPtion")
        console.log(questionOptions)
        setQuestionOptions([...questionOptions,
        { "option": question.option }])
        setQuestion({
            ...question,
            option: ""
        })
    }

    const onChangeFun = (e) => {
        let { name, value } = e.target
        setQuestion({
            ...question,
            [name]: value.trim()
        })
    }
    const deleteOption = (option) => {
        let indexOfOption = questionOptions.indexOf(option)
        console.log(indexOfOption)
        questionOptions.splice(questionOptions.indexOf(option), 1)
        setQuestionOptions([...questionOptions])
    }
    const selectGkCategoryTypeForAdditionalCategory = (e) => {
        // console.log(allNavItem[e.target.value])
        setSelectedSubCategoryAdditionalCat(allNavItem[e.target.value].subCategory)

    }
    const deleteAdditionalCat = (delValue) => {
        let newSelected = selected.filter(val => delValue != val.value)
        setSelected(newSelected)
    }
    return (
        <div className="container-fluid mt-3">
            <button className="btn btn-primary" onClick={() => setOpen(!open)}>Add GK</button>
            <Collapse in={open}>
                <div className="card">
                    <div className="card-header">
                        Add general Knowledge.
                    </div>
                    <div className="card-body">
                        <div className="row" style={{ marginTop: "1%" }}>
                            <div className="col-md-12">
                                <div className="container">

                                    <select className="form-control" onChange={selectGkCategoryType}>
                                        <option value="">Add GK For</option>
                                        {allNavItem.length > 0 && allNavItem.map((navItem, index) => (
                                            <option key={index} value={index}>{navItem.name}</option>
                                        ))}
                                    </select>
                                    <select className="form-control mt-2" onChange={selectGkTypeFun}>
                                        <option value="">Select Gk Type</option>
                                        {selectedSubCategory && selectedSubCategory.subCategory && selectedSubCategory.subCategory.length > 0 && selectedSubCategory.subCategory.map((subCat, ind) => (
                                            <option key={ind} value={subCat.value}>{subCat.name}</option>
                                        ))}
                                    </select>
                                    {/* <select className="form-control" onChange={selectGkCategoryType}>
                                        <option value="">Add GK For</option>
                                        {gkCategoryTypesMCQ && gkCategoryTypesMCQ.length > 0 && gkCategoryTypesMCQ.map((gktype, index) => (
                                            <option key={index} value={gktype.value}>{gktype.name}</option>
                                        ))}
                                    </select>
                                    <select className="form-control" onChange={selectGkTypeFun}>
                                        <option value="">Select Gk Type</option>
                                        {subCategoryType && subCategoryType.length > 0 && subCategoryType.map((gktype, index) => (
                                            <option key={index} value={gktype.value}>{gktype.name}</option>
                                        ))}
                                    </select> */}
                                    {selectedGkType &&
                                        <>
                                            <h5 align="center" className="mt-3">{selectedGkType} General Knowledge </h5>
                                            <AvForm onValidSubmit={addQuestions} className="row">
                                                <div className="col-lg-12">
                                                    {/* <h4 className="text-center mb-0 uppercase">Fill details for add question</h4> */}
                                                </div>



                                                <div className="col-md-12 input-group custom mb-0">
                                                    <div className="col-lg-12 custom mb-0">
                                                        <span>Question Type</span>
                                                        <AvField type="select" className="form-control bg-transparent" name="question_type"
                                                            value={question.question_type} onChange={onChangeFun}

                                                            validate={{
                                                                required: {
                                                                    value: true,
                                                                    errorMessage: "Please Enter Quiz Type",
                                                                },
                                                            }}
                                                            placeholder="Enter Quiz Question Type...">
                                                            <option disabled>Select Quiz Type</option>
                                                            <option value="checkbox">Checkbox</option>
                                                            <option value="radio">Radio</option>
                                                            <option value="text">Text</option>
                                                            <option value="number">Number</option>
                                                        </AvField>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 input-group custom mb-0">
                                                    <div className="col-lg-12 custom mb-0">
                                                        <span>Quiz Question</span>
                                                        <AvField className="form-control bg-transparent" name="question"
                                                            value={question.question} onChange={onChangeFun}

                                                            validate={{
                                                                required: {
                                                                    value: true,
                                                                    errorMessage: "Please Enter Quiz question",
                                                                },
                                                            }}
                                                            placeholder="Enter Meta Tag...">
                                                        </AvField>
                                                    </div>
                                                </div>

                                                <div className="col-md-6 input-group custom mb-0">
                                                    <div className="col-lg-12 custom mb-0">
                                                        <div className="card">
                                                            <div className="card-header">
                                                                <span>Add Option</span>
                                                            </div>
                                                            <div className="card-body">
                                                                <AvField className="form-control bg-transparent" name="option"
                                                                    value={question.option} onChange={onChangeFun}
                                                                    validate={{
                                                                        required: {
                                                                            value: false,
                                                                            errorMessage: "Please Enter Quiz Option",
                                                                        },
                                                                    }}
                                                                    placeholder="Enter Option...">
                                                                </AvField>
                                                                <button type="button" onClick={addingOption} className="btn btn-success rounded-0 button-room" >
                                                                    <i className="fa fa-plus" aria-hidden="true" /> Add Option</button>
                                                                {/* <div className="card-footer"> */}
                                                                {/* </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6 input-group custom mb-0">
                                                    <div className="col-lg-12 custom mb-0">
                                                        <div className="card" style={{ minHeight: "175px" }}>
                                                            <div className="card-header">
                                                                <span>Added Option</span>
                                                            </div>
                                                            <div className="card-body">

                                                                <ul class="list-group">
                                                                    {questionOptions && questionOptions.length > 0 && questionOptions.map((option, index) => (
                                                                        <li className="list-group-item p-0">{index + 1}. {option.option}
                                                                            {/* <button type="button" className="btn float-right" >
                                                                                <span className="fa fa-edit" aria-hidden="true" /> </button> */}
                                                                            <button onClick={() => deleteOption(option)} type="button" className="btn float-right" >
                                                                                <span className="fa fa-trash" /> </button>
                                                                        </li>
                                                                    ))}
                                                                    {questionOptions && questionOptions.length == 0 &&
                                                                        <h4 className="text-center">Option not added yet</h4>}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 input-group custom mb-0">
                                                    <div className="col-lg-12 custom mb-0">
                                                        <span>Answer</span>
                                                        <AvInput type="select" required className="form-control bg-transparent" name="answer"
                                                            value={question.answer} onChange={onChangeFun}

                                                            validate={{
                                                                required: {
                                                                    value: true,
                                                                    errorMessage: "Please Enter Quiz Answer",
                                                                },
                                                            }}
                                                            placeholder="Enter Answer...">
                                                            <option value="">Select Answer</option>
                                                            {questionOptions && questionOptions.length > 0 &&
                                                                questionOptions.map((op, index) => (
                                                                    <option value={op.option}>{index + 1} {op.option}</option>
                                                                ))}
                                                        </AvInput>
                                                    </div>
                                                </div>

                                                <div className="col-lg-12 input-group custom mb-0">
                                                    <div className="col-lg-12 custom mb-0">
                                                        {/* <span className="input-group-text bg-transparent  border border-0 text-white">
        <i className="fa fa-mobile" aria-hidden="true" /> &nbsp; */}
                                                        <span>Select Additional Category</span>
                                                        {/* </span> */}

                                                        <div className='row'>
                                                            <div className='col-md-6'>
                                                                <div className='mb-2'>
                                                                    <select className="form-control" onChange={selectGkCategoryTypeForAdditionalCategory}>
                                                                        <option value="">Add GK For</option>
                                                                        {allNavItem.length > 0 && allNavItem.map((navItem, index) => (
                                                                            <option key={index} value={index}>{navItem.name}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>

                                                                <MultiSelect
                                                                    options={selectedSubCategoryAdditionalCat.map(val => {
                                                                        val.label = val.name
                                                                        return val
                                                                    })}
                                                                    value={selected}
                                                                    onChange={setSelected}
                                                                    labelledBy="Select"
                                                                />
                                                            </div>
                                                            <div className='col-md-6'>
                                                                {selected.length > 0 && selected.map(val => (
                                                                    <button className='btn btn-info btn-sm rounded-pill p-2 mr-1'>{val.name} <i onClick={() => deleteAdditionalCat(val.value)} style={{ cursor: "pointer", color: "red" }} class="fa fa-trash" aria-hidden="true"></i></button>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-12 text-center text-center mt-3">
                                                    <button type="submit" className="btn btn-success btn-block rounded-0 button-room" >
                                                        <i className="fa fa-plus" aria-hidden="true" /> Add Question </button>
                                                </div>
                                            </AvForm>

                                        </>
                                    }

                                    <div>


                                    </div>
                                </div>
                            </div>

                        </div>
                        <ToastContainer align={"right"} />
                    </div>
                </div>
            </Collapse>
            <GetGkMultipleChoiceQuestion
                getAllQuestions={getAllQuestions}
                deleteQuestion={deleteQuestion} toggle={toggle}
                selectedGkType={selectedGkType}
                statusActiveInactive={statusActiveInactive} />
            <ModalEditMultipleChoiceQue
                modal={modal}
                toggle={toggle}
                editQuestion={editQuestion}
                questionForEdit={questionForEdit}
                allNavItem={allNavItem} selectGkCategoryTypeForAdditionalCategory={selectGkCategoryTypeForAdditionalCategory} selectedSubCategoryAdditionalCat={selectedSubCategoryAdditionalCat} selected={selected}
                setSelected={setSelected} deleteAdditionalCat={deleteAdditionalCat} />
            {/* <ToastContainer /> */}
        </div>
    );
}


export default AddMultipleChoiceQue;
