// SKIP_PREFLIGHT_CHECK=true
module.exports = {
  development: {
    site_api: 'https://api2.meansol.com'
    // site_api: "https://vijay.gkknow.com"
    // site_api: "https://api2.gkknow.com:5000"
  },
  production: {
    site_api: 'https://api2.meansol.com'
    // site_api: "https://localhost:5000"
    // site_api: "https://api2.gkknow.com:5000"
    // site_api: "http://localhost:5000"
  },
};



