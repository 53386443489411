import React, { useEffect, useState } from 'react';
// import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import {
    CNav,
    CNavItem,
    CNavLink,
    CRow,
    CTabContent,
    CTabPane,
    CModal,
    CTabs,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CBadge,
    CDataTable,
    CButton,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCol,
    CForm,
    CSelect,
    CFormGroup,
    CInputGroupAppend,
    CInputGroup,
    CLabel,
    CInputCheckbox,
    CSwitch,
    CFormText,
    CInput,
    CInputFile
} from '@coreui/react'
import AddSeo from './addSeo';

const ModalSeo = (props) => {
    const { modal, toggle, selectedForAddSeo } = props;
    return (
        <div className="">
            <CModal
                show={modal}
                onClose={() => toggle()}
                size="lg"
            >
                <CModalHeader closeButton>
                    <CModalTitle>SEO</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <AddSeo selectedForAddSeo={selectedForAddSeo}
                        toggle={toggle}
                         />
                </CModalBody>
                <CModalFooter>
                    <div className="float-right">
                        <CButton color="danger" onClick={toggle}><i class="fa fa-times" aria-hidden="true"></i> </CButton>{' '}
                    </div>
                </CModalFooter>
            </CModal>
        </div>
    );
}

export default ModalSeo;