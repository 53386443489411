
import React, { useState, useEffect } from 'react';
import API from '../../utils/apiCalling'
import { config } from '../../utils/apiUrl';
import { ToastContainer, toast } from 'react-toastify'
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
// import 'react-toastify/dist/ReactToastify.css';
import swal from "sweetalert";
import { Type } from '../../static/static'
import KendoTextEditor from 'views/common/kendo_text_editor';
import { CCollapse } from '@coreui/react'
const AddQuiz = (props) => {
    const { selectedUser, getAllQuiz, isAddQuiz, setIsAddQuiz } = props
    const api = new API()
    const [content, setContent] = useState()
    const [quizImage, setQuizImage] = useState()
 


    const [quiz, setQuiz] = useState({
        status: "",
        type: "",
        quiz_name: "",
        syllabus: content,
        quiz_image: "",
        quiz_title: "",
        meta_tag: "",
        meta_description: "",
        meta_title: "",
        quiz_attemp_permission: "",
        questions: []
    })
    // const notify = (message) => toast(message);

    useEffect(() => {

    }, [])
    const onChangeFun = (e) => {
        let { name, value } = e.target
        setQuiz({
            ...quiz,
            [name]: value
        })
    }
    const addQuizFun = () => {
        let data = quiz
        data.syllabus = content
        const formData = new FormData();
        formData.append("quizData", JSON.stringify(data))
        formData.append("quizImage", quizImage)
        console.log(data)
        swal({
            html: true,
            title: "Thank You!,",
            text: `Are you sure to add new quiz`,
            icon: "warning",
            buttons: ["No, cancel it!", "Add Quiz"],
            dangerMode: true,
        }).then(async function (isConfirm) {
            if (isConfirm) {
                let result = await api.postUpload(config.addQuiz, formData)
                if (result && result.code == 200) {
                    toast.success(`Hi, Thanks for adding new quiz!`)
                    reset()
                    setIsAddQuiz(!isAddQuiz)
                    getAllQuiz()
                    // getUser()
                } else {
                    toast.error(result && result.message)
                }
            }
        })
    }
    const reset = () => {
        setContent("")
        setQuiz({
            status: "",
            type: "",
            quiz_name: "",
            syllabus: content,
            quiz_image: "",
            quiz_title: "",
            meta_tag: "",
            meta_description: "",
            meta_title: "",
            quiz_attemp_permission: "",
            questions: []
        })
    }
    return (
        <div style={{ margin: "auto" }}>
        
                <CCollapse show={isAddQuiz}>
                    <div className="container">
                        <AvForm onValidSubmit={addQuizFun} className="row">
                            <div className="col-md-12">
                                <h4 className="text-center mb-0 uppercase">Add Quiz</h4>
                            </div>
                            <div className="col-md-6 input-group custom mb-0">
                                <div className="col-lg-12 custom mb-0">
                                    <span>Status</span>
                                    <AvField type="select" className="form-control bg-transparent" name="status"
                                        value={quiz.status} onChange={onChangeFun}

                                        validate={{
                                            required: {
                                                value: true,
                                                errorMessage: "Please Enter Quiz Type",
                                            },
                                        }}
                                        placeholder="Enter Quiz Type...">
                                        <option value="active">active</option>
                                        <option value="inactive">inactive</option>

                                    </AvField>
                                </div>
                            </div>
                            <div className="col-md-6 input-group custom mb-0">
                                <div className="col-lg-12 custom mb-0">
                                    <span>Quiz attemp permission</span>
                                    <AvField type="select" className="form-control bg-transparent" name="quiz_attemp_permission"
                                        value={quiz.quiz_attemp_permission} onChange={onChangeFun}

                                        validate={{
                                            required: {
                                                value: true,
                                                errorMessage: "Please Enter quiz attemp permission",
                                            },
                                        }}
                                        placeholder="Enter quiz attemp permission...">
                                        <option disabled>Please select user attemp quiz count</option>
                                        <option value="any">any</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                    </AvField>
                                </div>
                            </div>
                            <div className="col-md-6 input-group custom mb-0">
                                <div className="col-lg-12 custom mb-0">
                                    <span>Type</span>
                                    <AvField type="select" className="form-control bg-transparent" name="type"
                                        value={quiz.type} onChange={onChangeFun}

                                        validate={{
                                            required: {
                                                value: true,
                                                errorMessage: "Please Enter Quiz Type",
                                            },
                                        }}
                                        placeholder="Enter Quiz Type...">
                                        <option hidden>Select Quiz Type</option>
                                        {Type && Type.length > 0 && Type.map((type) => (
                                            <option value={type.type}>{type.tag}</option>
                                        ))}
                                    </AvField>
                                </div>
                            </div>
                            <div className="col-md-6 input-group custom mb-0">
                                <div className="col-lg-12 custom mb-0">
                                    <span>Meta Tag</span>
                                    <AvField className="form-control bg-transparent" name="meta_tag"
                                        value={quiz.meta_tag} onChange={onChangeFun}

                                        validate={{
                                            required: {
                                                value: true,
                                                errorMessage: "Please Enter Quiz Meta Tag",
                                            },
                                        }}
                                        placeholder="Enter Meta Tag...">
                                    </AvField>
                                </div>
                            </div>
                            <div className="col-md-6 input-group custom mb-0">
                                <div className="col-lg-12 custom mb-0">
                                    <span>Meta Description</span>
                                    <AvField className="form-control bg-transparent" name="meta_description"
                                        value={quiz.meta_description} onChange={onChangeFun}

                                        validate={{
                                            required: {
                                                value: true,
                                                errorMessage: "Please Enter Quiz Meta Description",
                                            },
                                        }}
                                        placeholder="Enter Meta Description...">
                                    </AvField>
                                </div>
                            </div>
                            <div className="col-lg-6 input-group custom mb-0">
                                <div className="col-lg-12 custom mb-0">
                                    <span>Meta Title</span>
                                    <AvField className="form-control bg-transparent" type="text" name="meta_title"
                                        value={quiz && quiz.meta_title} onChange={onChangeFun}
                                        validate={{
                                            required: {
                                                value: true,
                                                errorMessage: "Please Enter meta Title",
                                            },

                                        }}
                                        placeholder="Enter Meta Title..." />
                                </div>
                            </div>
                            <div className="col-lg-6 input-group custom mb-0">
                                <div className="col-lg-12 custom mb-0">
                                    <span>Quiz Title</span>
                                    <AvField className="form-control bg-transparent" type="text" name="quiz_title"
                                        value={quiz && quiz.quiz_title} onChange={onChangeFun}
                                        validate={{
                                            required: {
                                                value: true,
                                                errorMessage: "Please Enter Quiz Title",
                                            },

                                        }}
                                        placeholder="Enter Quiz Title..." />
                                </div>
                            </div>
                            <div className="col-lg-6 input-group custom mb-0">
                                <div className="col-lg-12 custom mb-0">
                                    <span>Quiz Name</span>
                                    <AvField className="form-control bg-transparent" type="text" name="quiz_name"
                                        value={quiz && quiz.quiz_name} onChange={onChangeFun}
                                        validate={{
                                            required: {
                                                value: true,
                                                errorMessage: "Please Enter Quiz Name",
                                            },

                                        }}
                                        placeholder="Enter Quiz Name..." />
                                </div>
                            </div>
                            <div className="col-md-6 input-group custom mb-0">
                                <div className="col-lg-12 custom mb-0">
                                    <span>Quiz Image</span>
                                    <AvInput className="form-control bg-transparent" type="file" name="quiz_image"
                                        value={quiz.quiz_image} onChange={(e) => setQuizImage(e.target.files[0])}
                                        validate={{
                                            required: {
                                                value: false,
                                                errorMessage: "Please Enter",
                                            },
                                        }}
                                        placeholder="Enter Quiz image..." />
                                </div>
                            </div>
                            <KendoTextEditor content={content} setContent={setContent} />
                            {/* <div className="col-lg-12 input-group custom mb-0">
                        <div className="col-lg-12 custom mb-0">
                            <span>Email</span>
                            <AvField className="form-control bg-transparent" type="text" name="email"
                                value={quiz && quiz.email} onChange={onChangeFun}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: "Please Enter Email",
                                    },

                                }}
                                placeholder="Enter Email..." />
                        </div>
                    </div> */}
                            {/* <div className="col-lg-12 input-group custom mb-0">
                        <div className="col-lg-12 custom mb-0">
                            <span>Mobile</span>
                            <AvField className="form-control bg-transparent" type="text" name="mobile"
                                value={quiz && quiz.mobile} onChange={onChangeFun}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: "Please Enter mobile",
                                    },

                                }}
                                placeholder="Enter mobile..." />
                        </div>
                    </div> */}
                            {/* <div className="col-lg-12 input-group custom mb-0">
                        <div className="col-lg-12 custom mb-0">
                            <span>Password</span>
                            <AvField className="form-control bg-transparent" type="text" name="password"
                                value={quiz && quiz.password} onChange={onChangeFun}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: "Please Enter Password",
                                    },

                                }}
                                placeholder="Enter Password..." />
                        </div>
                    </div> */}


                            <div className="col-lg-12 text-center text-center mt-3">
                                <button type="submit" className="btn btn-success btn-block rounded-0 button-room" >
                                    <i className="fa fa-plus" aria-hidden="true" /> Add User</button>
                            </div>
                        </AvForm>
                    </div>

                    {/* <ToastContainer /> */}
                </CCollapse>
        </div>
    );
}

export default AddQuiz;