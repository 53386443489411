
import React, { useState, useEffect } from 'react';
import API from '../../utils/apiCalling'
import { config } from '../../utils/apiUrl';
import { ToastContainer, toast } from 'react-toastify'
import ModalEditBlog from './modal';

import {
    CCollapse,
} from '@coreui/react'
const GetBlogSection = (props) => {
    const { blogAddedTrigger } = props
    const api = new API()
    const [getBlog, setGetBlog] = useState()
    const [active, setActive] = useState(0)
    const [blogForEdit, setBlogForEdit] = useState()
    const [modal, setModal] = useState(false)
    const [imageUrl, setImageUrl] = useState("https://vijay.gkknow.com/gkknow_blog_images")
    useEffect(() => {
        getAllBlog()
    }, [blogAddedTrigger])
    const getAllBlog = async () => {
        let result = await api.post(config.getBlogAdminPanel, "data")
        console.log("getting blog", result)
        if (result) {
            // console.log("getting blog data", result)
            setGetBlog(result)
        } else {
            console.log("something went wrong")
        }
    }

    const deleteBlog = async (blog) => {
        let data = {
            blog: blog._id
        }
        console.log("blog id is", data)
        swal({
            html: true,
            title: "Thank You!,",
            text: `Are you sure to delete blog`,
            icon: "success",
            buttons: ["No, cancel it!", "Delete"],
            dangerMode: true,
        }).then(async function (isConfirm) {
            if (isConfirm) {
                let result = await api.delete(config.deleteBlog, `${data.blog}`)
                console.log("getting blog", result)
                if (result) {
                    // console.log("getting blog data", result)

                    toast.success(`Hi, Thanks for add blog!`)
                    getAllBlog()
                } else {
                    console.log("something went wrong")
                }
            }
        })
    }

    const editBlog = (blog) => {
        swal({
            html: true,
            title: "Thank You!,",
            text: `Are you sure to edit blog`,
            icon: "success",
            buttons: ["No, cancel it!", "Edit"],
            dangerMode: true,
        }).then(async function (isConfirm) {
            if (isConfirm) {

                let result = await api.put(config.updateBlog, blog)
                if (result) {
                    console.log("edit blog is", result)
                    toast.success(`Hi, Thanks for edit blog!`)
                    getAllBlog()
                    setModal(!modal)
                }
                else {
                    toast.success(`Hi, Something went wrong!`)
                }
            }
        }
        )
        alert("blog for edit")
    }

    const toggle = (blog) => {
        setBlogForEdit(blog)
        setModal(!modal)
    }

    const blogActiveInactive = (blog) => {
        let status = blog.status == "active" ? "inactive" : "active";
        let data = {
            id: blog._id,
            status: status
        }
        console.log(data)
        swal({
            html: true,
            title: "Thank You!,",
            text: `Are you sure to ${status} blog`,
            icon: "success",
            buttons: ["No, cancel it!", "Submit"],
            dangerMode: true,
        }).then(async function (isConfirm) {
            if (isConfirm) {
                let result = await api.post(config.blogStatusUpdate, data)
                if (result && result.code == 200) {
                    console.log("edit blog is", result)
                    toast.success(result.message)
                    getAllBlog()
                }
                else {
                    toast.success(result && result.code == 200)
                }
            }
        }
        )
    }
    return (

        <div style={{ margin: "auto" }}>
            <h5 align="center" className="mb-3">All Blog</h5>
            <div style={{ margin: "auto", height: "auto", overflow: "auto" }}>
                <div className="row">
                    {getBlog && getBlog.length > 0 && getBlog.map((blog, index) => (
                        <div className="col-md-6">
                            <div key={index} className="card mb-2">
                                <div className="card-header">
                                    <span>{index + 1} {blog.blog_heading}</span>

                                    <button className="btn btn-info float-right" onClick={() => blogActiveInactive(blog)}>
                                        {blog && blog.status}
                                    </button>
                                </div>
                                <div className="card-body">
                                    <form>

                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Blog Type</label>
                                            <input value={blog.blog_type} disabled className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Blog Tag</label>
                                            <input type="text" value={blog.blog_tag} disabled className="form-control" />
                                        </div>
                                        <CCollapse show={active}>
                                            <>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">Blog sub heading</label>
                                                    <input type="text" value={blog.blog_sub_heading} disabled className="form-control" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">Blog blog_content</label>
                                                    <textarea value={blog.blog_content} disabled className="form-control" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">Blog blog_sub_content_1</label>
                                                    <textarea value={blog.blog_sub_content_1} disabled className="form-control" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">Blog blog_sub_content_2</label>
                                                    <textarea value={blog.blog_sub_content_2} disabled className="form-control" />
                                                </div>
                                            </>
                                        </CCollapse>
                                        <div className="row text-center">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">Blog image</label><br></br>
                                                    <img src={`${imageUrl}/${blog && blog.image1}`} alt="..." height={150} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">Blog sub image</label><br></br>
                                                    <img src={`${imageUrl}/${blog && blog.image2}`} alt="..." height={150} />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="card-footer">
                                    <button type="submit" onClick={() => toggle(blog)} className="btn btn-primary">Edit</button>
                                    <button style={{ marginLeft: "5px" }} onClick={() => deleteBlog(blog)} type="submit" className="btn btn-danger">Delete</button>
                                    <button onClick={() => setActive(!active)} type="submit" className="btn btn-info float-right">{active ? <i class="fa fa-arrow-up" aria-hidden="true"></i> : <i class="fa fa-arrow-down" aria-hidden="true"></i>}</button>
                                </div>
                                {/* <hr/> */}
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <ModalEditBlog modal={modal} toggle={toggle}
                editBlog={editBlog}
                blogForEdit={blogForEdit} />
        </div>



    );
}

export default GetBlogSection;