
import React, { useState, useEffect, Component } from 'react';
import {Route, Redirect } from "react-router-dom";
import { getLocalDataAsObject } from 'utils/CoustomStorage';
import auth from './auth';

export const ProtectedRoute = ({component: Component, ...rest}) => {
    let token = getLocalDataAsObject("token")
    // console.log("token isssssssssssssssssss", token)
return (
    
    <Route
    {...rest}
    render={props => {
        if(token) {
            return <Component {...props} />
        } else {
            return <Redirect to={
                {
                    pathname:"/",
                    state: {
                        from: props.location
                    }
                }
            } />
        }
    }}
    />
);
};


