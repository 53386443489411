
import React, { useState, useEffect } from 'react';
import AddBlogSection from './addBlogSection';

const BlogSection = (props) => {
    const { } = props

    return (
        <div className="container-fluid">
            <AddBlogSection />
            {/* <h2>blog section</h2> */}
        </div>
    );
}

export default BlogSection;