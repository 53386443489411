import { getLocalDataAsObject } from "utils/CoustomStorage";

class Auth {
    constructor() {
        this.authenticated = false
        let token = getLocalDataAsObject("token")
        // console.log("token is-----------------------", token)
        if (token) {
            // alert(token)
            this.authenticated = true;
        }

    }
    isAuthenticated() {
        return this.authenticated
    }
}
export default new Auth();