
import React from "react";
import ReactDOM from "react-dom";

import { BrowserRouter, Route, Switch, Redirect, HashRouter } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0";
import "./assets/css/demo.css";
import "./assets/css/style.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import AdminLayout from "layouts/Admin.js";
import Login from "views/login/login";
import { ProtectedRoute } from "./auth_guard";
import { clearLocalData } from "utils/CoustomStorage";


ReactDOM.render(
  <HashRouter>
  
    <Switch>
      <Route path="/login" render={(props) => <Login {...props} />} />
      {/* <Route path="/admin" render={(props) => <AdminLayout {...props} />} /> */}
      <ProtectedRoute path="/admin" component={AdminLayout} />
      <Redirect from="/" to="/login" />
    </Switch>
  </HashRouter>,
  document.getElementById("root")
);
