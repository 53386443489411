import React, { useEffect, useState } from "react";
import ChartistGraph from "react-chartist";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";

import API from '../utils/apiCalling'
import { config } from '../utils/apiUrl';
import Moment from 'react-moment';
import { clearLocalData } from "utils/CoustomStorage";
function Dashboard() {
  let history = useHistory();
  const api = new API()
  const [quizParticipant, setQuizParticipant] = useState()
  const [allQuery, setAllQuery] = useState()

  useEffect(() => {
    getQuery()
    getQuizParticipant()
    tokenVerify()
  }, [])
  const tokenVerify = async() => {
    let result = await api.get(config.token_verify)
    if(result && result.code == 200) {
        console.log("verifyed")
    } else {
        // console.log("inside else clear token")
        // console.log("not verifyed")
        clearLocalData()
        history.push('/login')
    }
}
  const getQuery = async () => {
    let result = await api.post(config.getQuery, "data")
    console.log("query", result)
    if (result) {
      // console.log("getting blog data", result)
      setAllQuery(result)
    } else {
      console.log("something went wrong")
    }
  }
  const getQuizParticipant = async () => {
    let result = await api.post(config.getParticipant, "data")
    console.log("getting quiz participant", result)
    if (result) {
      // console.log("getting blog data", result)
      setQuizParticipant(result)
    } else {
      console.log("something went wrong")
    }
  }
  return (
    <>
      <Container fluid>
        <Row>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-chart text-warning"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Total Participant</p>
                      <Card.Title as="h4">{quizParticipant && quizParticipant.length}</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <i className="fas fa-redo mr-1"></i>
                  Update Now
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-light-3 text-success"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Total Query</p>
                      <Card.Title as="h4">{allQuery && allQuery.length}</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <i className="far fa-calendar-alt mr-1"></i>
                  Last day
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-vector text-danger"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">New Updated</p>
                      <Card.Title as="h4">--</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <i className="far fa-clock-o mr-1"></i>
                  In the last hour
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-favourite-28 text-primary"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Reports</p>
                      <Card.Title as="h4">0</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <i className="fas fa-redo mr-1"></i>
                  Update now
                </div>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="8">
            <Card style={{ minHeight: "400px  " }}>
              <Card.Header>
                <Card.Title as="h4">Exercise Participant</Card.Title>
                <p className="card-category">User</p>
              </Card.Header>
              <Card.Body style={{ maxHeight: "500px", overflow: "auto" }}>
                {quizParticipant && quizParticipant.length > 0 && quizParticipant.map((quiz, index) => (
                  <div className="card">
                    <div className="card-header" style={{backgroundColor:"#6f4e37"}}>
                      <p>{quiz.name}</p>
                    </div>
                    <div className="card-body">
                      <p>{quiz.mobile}</p>
                      <p>{quiz.email}</p>
                    </div>
                  </div>
                ))}
              </Card.Body>
              <Card.Footer>

              </Card.Footer>
            </Card>
          </Col>
          <Col md="4">
            <Card style={{ minHeight: "400px  " }}>
              <Card.Header>
                <Card.Title as="h4">Query</Card.Title>
                <p className="card-category">Public Query</p>
              </Card.Header>
              <Card.Body style={{ maxHeight: "500px", overflow: "auto" }}>
                {allQuery && allQuery.length > 0 && allQuery.map((query, index) => (
                  <div>
                    <div className="container query_section">
                      <img src={require("assets/img/default-avatar.png").default} alt="Avatar" />
                      <p>{query.name}</p>
                      <p>{query.mobile}</p>
                      <p>{query.message}</p>

                      <span className="time-right" >
                        <Moment format="DD-MM-YYYY HH:mm">
                        {query.date}
                      </Moment></span>
                    </div>
                  </div>
                ))}

                <hr></hr>
                <div className="stats">
                  <i className="far fa-clock"></i>
                  Last Query 2 hour ago
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>

      </Container>
    </>
  );
}

export default Dashboard;
