
import Dashboard from "views/Dashboard.js";
import UserProfile from "views/UserProfile.js";
import TableList from "views/TableList.js";
import Typography from "views/Typography.js";
import Icons from "views/Icons.js";
import Maps from "views/Maps.js";
import Notifications from "views/Notifications.js";
import GeneralKnowledgeDashboard from "views/general-knowledge";
import QuestionAnswerSection from "views/general-knowledge/question-answer-gk";
import MultipleChoiceQuestionSection from "views/general-knowledge/multiple-question-gk";
import BlogSection from "views/blog";
import UserManagement from "views/user";
import { clearLocalData } from "utils/CoustomStorage";
import Quiz from "views/quiz";
import Seo from "views/seo";





const dashboardRoutes = [
  {
    NavShow:true,
    upgrade: true,
    path: "/dashboard",
    name: "www.gkknwo.com",
    icon: "nc-icon nc-alien-33",
    component: Dashboard,
    layout: "/admin",
  },
  {
    NavShow:true,
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-chart-pie-35",
    component: Dashboard,
    layout: "/admin",
  },
  {
    NavShow:true,
    path: "/gk",
    name: "gk",
    icon: "nc-icon nc-alien-33",
    component: GeneralKnowledgeDashboard,
    layout: "/admin",
  },
  {
    NavShow:true,
    path: "/blog",
    name: "blog",
    icon: "nc-icon nc-alien-33",
    component: BlogSection,
    layout: "/admin",
  },
  {
    exact:true,
    NavShow:false,
    path: "/gk/add-gk",
    name: "add-gk",
    icon: "nc-icon nc-alien-33",
    component: QuestionAnswerSection,
    layout: "/admin",
  },
  {
    exact:true,
    NavShow:false,
    path: "/gk/add-multiple-choice-gk",
    name: "add-gk",
    icon: "nc-icon nc-alien-33",
    component: MultipleChoiceQuestionSection,
    layout: "/admin",
  },
  {
    NavShow:true,
    path: "/quiz-management",
    name: "Quiz Management",
    icon: "nc-icon nc-circle-09",
    component: Quiz,
    layout: "/admin",
  },
  {
    NavShow:true,
    path: "/quiz-management",
    name: "ADS Management",
    icon: "nc-icon nc-circle-09",
    component: Quiz,
    layout: "/admin",
  },
  {
    NavShow:true,
    path: "/seo",
    name: "SEO",
    icon: "nc-icon nc-circle-09",
    component: Seo,
    layout: "/admin",
  },
  {
    NavShow:true,
    path: "/user",
    name: "User Management",
    icon: "nc-icon nc-circle-09",
    component: UserManagement,
    layout: "/admin",
  },
  // {
  //   NavShow:true,
  //   path: "/table",
  //   name: "Table List",
  //   icon: "nc-icon nc-notes",
  //   component: TableList,
  //   layout: "/admin",
  // },
  // {
  //   NavShow:true,
  //   path: "/typography",
  //   name: "Typography",
  //   icon: "nc-icon nc-paper-2",
  //   component: Typography,
  //   layout: "/admin",
  // },
  // {
  //   NavShow:true,
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "nc-icon nc-atom",
  //   component: Icons,
  //   layout: "/admin",
  // },
  // {
  //   NavShow:true,
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "nc-icon nc-pin-3",
  //   component: Maps,
  //   layout: "/admin",
  // },
  // {
  //   NavShow:true,
  //   path: "/notifications",
  //   name: "Notifications",
  //   icon: "nc-icon nc-bell-55",
  //   component: Notifications,
  //   layout: "/admin",
  // },
];

export default dashboardRoutes;
