export let Type = [
    { type: "all", tag: "All Gk" },
    { type: "current", tag: "Current Gk" }, 
    { type: "medical", tag: "Medical blog" },
    { type: "gk", tag: "gk" },
    { type: "computer", tag: "Computer Gk" },
    { type: "reasoning", tag: "Reasoning Gk" },
    { type: "math", tag: "Math Gk" },
    { type: "hindi", tag: "Hindi Gk" },
    { type: "english", tag: "English Gk" },
    { type: "sports", tag: "Sports Gk" },
    { type: "bollywood", tag: "Bollywood Gk" },
    
]

export const gkCategoryTypes = [
    { name: "GK Category", value: "gkType" },
    { name: "State GK", value: "stateGkType" },
    { name: "other", value: "other" },
]
export let gkType = [
    { name: "Current General knowledge", value: "current-general-knowledge" },
    { name: "India General knowledge", value: "india-general-knowledge" },
    { name: "World General knowledge", value: "world-general-knowledge" },
    { name: "Political General knowledge", value: "political-general-knowledge" },
    { name: "Sports General knowledge", value: "sports-general-knowledge" },
    { name: "Biology General knowledge", value: "biology-general-knowledge" },
    { name: "Bollywood General knowledge", value: "bollywood-general-knowledge" },
    { name: "History General knowledge", value: "history-general-knowledge" },
    { name: "Geographical General knowledge", value: "geographical-general-knowledge" },
    { name: "Economics General knowledge", value: "economics-general-knowledge" },
    { name: "Medical General knowledge", value: "medical-general-knowledge" },
    { name: "Computer General knowledge", value: "computer-general-knowledge" },
    { name: "Religion General knowledge", value: "religion-general-knowledge" },
]
export let stateGkType = [
    { value: "andra-pradesh-general-knowledge", name: "Andhra Pradesh Gk" },
    { value: "arunchal-pradesh-general-knowledge", name: "Arunachal Pradesh Gk" },
    { value: "assam-general-knowledge", name: "Assam Gk" },
    { value: "bihar-general-knowledge", name: "Bihar Gk" },
    { value: "chhattisgarh-general-knowledge", name: "Chhattisgarh Gk" },
    { value: "goa-general-knowledge", name: "Goa Gk" },
    { value: "gujrat-general-knowledge", name: "Gujarat Gk" },
    { value: "haryana-general-knowledge", name: "Haryana Gk" },
    { value: "himachal-pradesh-general-knowledge", name: "Himachal Pradesh Gk" },
    { value: "jharkhand-general-knowledge", name: "Jharkhand Gk" },
    { value: "karnataka-general-knowledge", name: "Karnataka Gk" },
    { value: "kerala-general-knowledge", name: "Kerala Gk" },
    { value: "madhya-pradesh-general-knowledge", name: "Madhya Pradesh Gk" },
    { value: "maharashtra-general-knowledge", name: "Maharashtra Gk" },
    { value: "manipur-general-knowledge", name: "Manipur Gk" },
    { value: "meghalaya-general-knowledge", name: "Meghalaya Gk" },
    { value: "mizoram-general-knowledge", name: "Mizoram Gk" },
    { value: "nagaland-general-knowledge", name: "Nagaland Gk" },
    { value: "odisha-general-knowledge", name: "Odisha Gk" },
    { value: "punjab-general-knowledge", name: "Punjab Gk" },
    { value: "rajasthan-general-knowledge", name: "Rajasthan Gk" },
    { value: "sikkim-general-knowledge", name: "Sikkim Gk" },
    { value: "tamil-nadu-general-knowledge", name: "Tamil Nadu Gk" },
    { value: "telangana-general-knowledge", name: "Telangana Gk" },
    { value: "tripura-general-knowledge", name: "Tripura Gk" },
    { value: "uttar-pradesh-general-knowledge", name: "Uttar Pradesh Gk" },
    { value: "uttarakhand-general-knowledge", name: "Uttarakhand Gk" },
    { value: "west-bengal-general-knowledge", name: "West Bengal Gk" },
]
export let other = [
    { value: "general-general-knowledge", name: "General General Knowledge"},
    { value: "river-general-knowledge", name: "River General Knowledge" },
    { value: "mountain-general-knowledge", name: "mountain General Knowledge" },
    { value: "forest-general-knowledge", name: "Forest General Knowledge" },
    { value: "universe-general-knowledge", name: "Universe General Knowledge"},
]

// MCQ
export let gkCategoryTypesMCQ = [
    { name: "GK Category", value: "gkType" },
    { name: "State GK", value: "stateGkType" },
    { name: "other", value: "other" },
]

export let gkTypeMCQ = [
    { name: "India General knowledge", value: "india-general-knowledge-mcq" },    //indiaGK_MultipleChoice
    { name: "Current General knowledge", value: "current-general-knowledge-mcq" },
    { name: "World General knowledge", value: "world-general-knowledge-mcq" },
    { name: "Political General knowledge", value: "political-general-knowledge-mcq" },
    { name: "Sports General knowledge", value: "sports-general-knowledge-mcq" },
    { name: "Biology General knowledge", value: "biology-general-knowledge-mcq" },
    { name: "Bollywood General knowledge", value: "bollywood-general-knowledge-mcq" },
    { name: "History General knowledge", value: "history-general-knowledge-mcq" },
    { name: "Geographical General knowledge", value: "geographical-general-knowledge-mcq" },
    { name: "Economics General knowledge", value: "economics-general-knowledge-mcq" },
    { name: "Medical General knowledge", value: "medical-general-knowledge-mcq" },
    { name: "Computer General knowledge", value: "computer-general-knowledge-mcq" },
]
export let stateGkTypeMCQ = [
    { value: "andra-pradesh-general-knowledge-mcq", name: "Andhra Pradesh Gk" },
    { value: "arunchal-pradesh-general-knowledge-mcq", name: "Arunachal Pradesh Gk" },
    { value: "assam-general-knowledge-mcq", name: "Assam Gk" },
    { value: "bihar-general-knowledge-mcq", name: "Bihar Gk" },
    { value: "chhattisgarh-general-knowledge-mcq", name: "Chhattisgarh Gk" },
    { value: "goa-general-knowledge-mcq", name: "Goa Gk" },
    { value: "gujrat-general-knowledge-mcq", name: "Gujarat Gk" },
    { value: "haryana-general-knowledge-mcq", name: "Haryana Gk" },
    { value: "himachal-pradesh-general-knowledge-mcq", name: "Himachal Pradesh Gk" },
    { value: "jharkhand-general-knowledge-mcq", name: "Jharkhand Gk" },
    { value: "karnataka-general-knowledge-mcq", name: "Karnataka Gk" },
    { value: "kerala-general-knowledge-mcq", name: "Kerala Gk" },
    { value: "madhya-pradesh-general-knowledge-mcq", name: "Madhya Pradesh Gk" },
    { value: "maharashtra-general-knowledge-mcq", name: "Maharashtra Gk" },
    { value: "manipur-general-knowledge-mcq", name: "Manipur Gk" },
    { value: "meghalaya-general-knowledge-mcq", name: "Meghalaya Gk" },
    { value: "mizoram-general-knowledge-mcq", name: "Mizoram Gk" },
    { value: "nagaland-general-knowledge-mcq", name: "Nagaland Gk" },
    { value: "odisha-general-knowledge-mcq", name: "Odisha Gk" },
    { value: "punjab-general-knowledge-mcq", name: "Punjab Gk" },
    { value: "rajasthan-general-knowledge-mcq", name: "Rajasthan Gk" },
    { value: "sikkim-general-knowledge-mcq", name: "Sikkim Gk" },
    { value: "tamil-nadu-general-knowledge-mcq", name: "Tamil Nadu Gk" },
    { value: "telangana-general-knowledge-mcq", name: "Telangana Gk" },
    { value: "tripura-general-knowledge-mcq", name: "Tripura Gk" },
    { value: "uttar-pradesh-general-knowledge-mcq", name: "Uttar Pradesh Gk" },
    { value: "uttarakhand-general-knowledge-mcq", name: "Uttarakhand Gk" },
    { value: "west-bengal-general-knowledge-mcq", name: "West Bengal Gk" },
]
export let otherMCQ = [
    { value: "general-general-knowledge-mcq", name: "General General Knowledge"},
    { value: "river-general-knowledge-mcq", name: "River GK" },
    {value : "mountain-general-knowledge-mcq", name: "mountain GK"},
    { value: "forest-general-knowledge-mcq", name: "Forest General Knowledge" },
    { value: "universe-general-knowledge-mcq", name: "Universe General Knowledge" },
]

// ___________________________________________________________________________

// export const navData = {
//     "type": "question_answer", label:"navDataQueAns", data: [
//         {
//             name: "GK Category", value: "gkType", subCategory: [
//                 { name: "Current General knowledge", value: "current-general-knowledge", label: "", tag: "" },
//                 { name: "India General knowledge", value: "india-general-knowledge", label: "", tag: "" },
//                 { name: "World General knowledge", value: "world-general-knowledge", label: "", tag: "" },
//                 { name: "Political General knowledge", value: "political-general-knowledge", label: "", tag: "" },
//                 { name: "Sports General knowledge", value: "sports-general-knowledge", label: "", tag: "" },
//                 { name: "Biology General knowledge", value: "biology-general-knowledge", label: "", tag: "" },
//                 { name: "Bollywood General knowledge", value: "bollywood-general-knowledge", label: "", tag: "" },
//                 { name: "History General knowledge", value: "history-general-knowledge", label: "", tag: "" },
//                 { name: "Geographical General knowledge", value: "geographical-general-knowledge", label: "", tag: "" },
//                 { name: "Economics General knowledge", value: "economics-general-knowledge", label: "", tag: "" },
//                 { name: "Medical General knowledge", value: "medical-general-knowledge", label: "", tag: "" },
//                 { name: "Computer General knowledge", value: "computer-general-knowledge", label: "", tag: "" },
//                 { name: "Religion General knowledge", value: "religion-general-knowledge", label: "", tag: "" }
//             ]
//         },
//         {
//             name: "State GK", value: "stateGkType", subCategory: [
//                 { value: "andra-pradesh-general-knowledge", name: "Andhra Pradesh Gk", label: "", tag: "" },
//                 { value: "arunchal-pradesh-general-knowledge", name: "Arunachal Pradesh Gk", label: "", tag: "" },
//                 { value: "assam-general-knowledge", name: "Assam Gk", label: "", tag: "" },
//                 { value: "bihar-general-knowledge", name: "Bihar Gk", label: "", tag: "" },
//                 { value: "chhattisgarh-general-knowledge", name: "Chhattisgarh Gk", label: "", tag: "" },
//                 { value: "goa-general-knowledge", name: "Goa Gk", label: "", tag: "" },
//                 { value: "gujrat-general-knowledge", name: "Gujarat Gk", label: "", tag: "" },
//                 { value: "haryana-general-knowledge", name: "Haryana Gk", label: "", tag: "" },
//                 { value: "himachal-pradesh-general-knowledge", name: "Himachal Pradesh Gk", label: "", tag: "" },
//                 { value: "jharkhand-general-knowledge", name: "Jharkhand Gk", label: "", tag: "" },
//                 { value: "karnataka-general-knowledge", name: "Karnataka Gk", label: "", tag: "" },
//                 { value: "kerala-general-knowledge", name: "Kerala Gk", label: "", tag: "" },
//                 { value: "madhya-pradesh-general-knowledge", name: "Madhya Pradesh Gk", label: "", tag: "" },
//                 { value: "maharashtra-general-knowledge", name: "Maharashtra Gk", label: "", tag: "" },
//                 { value: "manipur-general-knowledge", name: "Manipur Gk", label: "", tag: "" },
//                 { value: "meghalaya-general-knowledge", name: "Meghalaya Gk", label: "", tag: "" },
//                 { value: "mizoram-general-knowledge", name: "Mizoram Gk", label: "", tag: "" },
//                 { value: "nagaland-general-knowledge", name: "Nagaland Gk", label: "", tag: "" },
//                 { value: "odisha-general-knowledge", name: "Odisha Gk", label: "", tag: "" },
//                 { value: "punjab-general-knowledge", name: "Punjab Gk", label: "", tag: "" },
//                 { value: "rajasthan-general-knowledge", name: "Rajasthan Gk", label: "", tag: "" },
//                 { value: "sikkim-general-knowledge", name: "Sikkim Gk", label: "", tag: "" },
//                 { value: "tamil-nadu-general-knowledge", name: "Tamil Nadu Gk", label: "", tag: "" },
//                 { value: "telangana-general-knowledge", name: "Telangana Gk", label: "", tag: "" },
//                 { value: "tripura-general-knowledge", name: "Tripura Gk", label: "", tag: "" },
//                 { value: "uttar-pradesh-general-knowledge", name: "Uttar Pradesh Gk", label: "", tag: "" },
//                 { value: "uttarakhand-general-knowledge", name: "Uttarakhand Gk", label: "", tag: "" },
//                 { value: "west-bengal-general-knowledge", name: "West Bengal Gk", label: "", tag: "" }
//             ]
//         },
//         {
//             name: "other", value: "other", subCategory: [
//                 { value: "general-general-knowledge", name: "General General Knowledge", label: "", tag: "" },
//                 { value: "river-general-knowledge", name: "River General Knowledge", label: "", tag: "" },
//                 { value: "mountain-general-knowledge", name: "mountain General Knowledge", label: "", tag: "" },
//                 { value: "forest-general-knowledge", name: "Forest General Knowledge", label: "", tag: "" },
//                 { value: "universe-general-knowledge", name: "Universe General Knowledge", label: "", tag: "" }
//             ]
//         }
//     ]
// }

// export const navDataMCQ = {
//     "type": "mcq", label:"navDataMCQ", data: [
//         {
//             name: "GK Category", value: "gkTypeMCQ", subCategory: [
//                 { name: "India General knowledge", value: "india-general-knowledge-mcq", label: "", tag: "" },    //indiaGK_MultipleChoice
//                 { name: "Current General knowledge", value: "current-general-knowledge-mcq", label: "", tag: "" },
//                 { name: "World General knowledge", value: "world-general-knowledge-mcq", label: "", tag: "" },
//                 { name: "Political General knowledge", value: "political-general-knowledge-mcq", label: "", tag: "" },
//                 { name: "Sports General knowledge", value: "sports-general-knowledge-mcq", label: "", tag: "" },
//                 { name: "Biology General knowledge", value: "biology-general-knowledge-mcq", label: "", tag: "" },
//                 { name: "Bollywood General knowledge", value: "bollywood-general-knowledge-mcq", label: "", tag: "" },
//                 { name: "History General knowledge", value: "history-general-knowledge-mcq", label: "", tag: "" },
//                 { name: "Geographical General knowledge", value: "geographical-general-knowledge-mcq", label: "", tag: "" },
//                 { name: "Economics General knowledge", value: "economics-general-knowledge-mcq", label: "", tag: "" },
//                 { name: "Medical General knowledge", value: "medical-general-knowledge-mcq", label: "", tag: "" },
//                 { name: "Computer General knowledge", value: "computer-general-knowledge-mcq", label: "", tag: "" }
//             ]
//         },
//         {
//             name: "State GK", value: "stateGkTypeMCQ", subCategory: [
//                 { value: "andra-pradesh-general-knowledge-mcq", name: "Andhra Pradesh Gk", label: "", tag: "" },
//                 { value: "arunchal-pradesh-general-knowledge-mcq", name: "Arunachal Pradesh Gk", label: "", tag: "" },
//                 { value: "assam-general-knowledge-mcq", name: "Assam Gk", label: "", tag: "" },
//                 { value: "bihar-general-knowledge-mcq", name: "Bihar Gk", label: "", tag: "" },
//                 { value: "chhattisgarh-general-knowledge-mcq", name: "Chhattisgarh Gk", label: "", tag: "" },
//                 { value: "goa-general-knowledge-mcq", name: "Goa Gk", label: "", tag: "" },
//                 { value: "gujrat-general-knowledge-mcq", name: "Gujarat Gk", label: "", tag: "" },
//                 { value: "haryana-general-knowledge-mcq", name: "Haryana Gk", label: "", tag: "" },
//                 { value: "himachal-pradesh-general-knowledge-mcq", name: "Himachal Pradesh Gk", label: "", tag: "" },
//                 { value: "jharkhand-general-knowledge-mcq", name: "Jharkhand Gk", label: "", tag: "" },
//                 { value: "karnataka-general-knowledge-mcq", name: "Karnataka Gk", label: "", tag: "" },
//                 { value: "kerala-general-knowledge-mcq", name: "Kerala Gk", label: "", tag: "" },
//                 { value: "madhya-pradesh-general-knowledge-mcq", name: "Madhya Pradesh Gk", label: "", tag: "" },
//                 { value: "maharashtra-general-knowledge-mcq", name: "Maharashtra Gk", label: "", tag: "" },
//                 { value: "manipur-general-knowledge-mcq", name: "Manipur Gk", label: "", tag: "" },
//                 { value: "meghalaya-general-knowledge-mcq", name: "Meghalaya Gk", label: "", tag: "" },
//                 { value: "mizoram-general-knowledge-mcq", name: "Mizoram Gk", label: "", tag: "" },
//                 { value: "nagaland-general-knowledge-mcq", name: "Nagaland Gk", label: "", tag: "" },
//                 { value: "odisha-general-knowledge-mcq", name: "Odisha Gk", label: "", tag: "" },
//                 { value: "punjab-general-knowledge-mcq", name: "Punjab Gk", label: "", tag: "" },
//                 { value: "rajasthan-general-knowledge-mcq", name: "Rajasthan Gk", label: "", tag: "" },
//                 { value: "sikkim-general-knowledge-mcq", name: "Sikkim Gk", label: "", tag: "" },
//                 { value: "tamil-nadu-general-knowledge-mcq", name: "Tamil Nadu Gk", label: "", tag: "" },
//                 { value: "telangana-general-knowledge-mcq", name: "Telangana Gk", label: "", tag: "" },
//                 { value: "tripura-general-knowledge-mcq", name: "Tripura Gk", label: "", tag: "" },
//                 { value: "uttar-pradesh-general-knowledge-mcq", name: "Uttar Pradesh Gk", label: "", tag: "" },
//                 { value: "uttarakhand-general-knowledge-mcq", name: "Uttarakhand Gk", label: "", tag: "" },
//                 { value: "west-bengal-general-knowledge-mcq", name: "West Bengal Gk", label: "", tag: "" }
//             ]
//         },
//         {
//             name: "other", value: "otherMCQ", subCategory: [
//                 { value: "general-general-knowledge-mcq", name: "General General Knowledge", label: "", tag: "" },
//                 { value: "river-general-knowledge-mcq", name: "River GK", label: "", tag: "" },
//                 { value: "mountain-general-knowledge-mcq", name: "mountain GK", label: "", tag: "" },
//                 { value: "forest-general-knowledge-mcq", name: "Forest General Knowledge", label: "", tag: "" },
//                 { value: "universe-general-knowledge-mcq", name: "Universe General Knowledge", label: "", tag: "" }
//             ]
//         }
//     ]
// }