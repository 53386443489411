
import React, { useState, useEffect } from 'react';
import API from '../../utils/apiCalling'
import { useHistory } from "react-router-dom";
import {
    Badge,
    Button,
    Card,
    Navbar,
    Nav,
    Table,
    Container,
    Row,
    Col,
    Form,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import './index.css'

const GkTab = () => {

    return (

        <Container fluid>
            <Row>
                <Col>
                    <NavLink to={"/admin/gk/add-gk"}>
                        <Card className="card-stats">
                            <Card.Body>
                                <Row>
                                    <Col xs="5">
                                        <div className="icon-big text-center icon-warning">
                                            <i className="nc-icon nc-chart text-warning"></i>
                                        </div>
                                    </Col>
                                    <Col xs="7">
                                        <div className="numbers">
                                            <Card.Title as="h4">General Knowledge</Card.Title>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                            <Card.Footer>
                                <hr></hr>
                                <div className="stats">
                                    <i className="fas fa-redo mr-1"></i>
                                    Add Now
                                </div>
                            </Card.Footer>
                        </Card>
                    </NavLink>
                </Col>
                <Col>
                <NavLink to="/admin/gk/add-multiple-choice-gk">
                    <Card className="card-stats">
                        <Card.Body>
                            <Row>
                                <Col xs="5">
                                    <div className="icon-big text-center icon-warning">
                                        <i className="nc-icon nc-chart text-warning"></i>
                                    </div>
                                </Col>
                                <Col xs="7">
                                    <div className="numbers">
                                        <Card.Title as="h4">Multiple Choice GK</Card.Title>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer>
                            <hr></hr>
                            <div className="stats">
                                <i className="fas fa-redo mr-1"></i>
                                Add Now
                            </div>
                        </Card.Footer>
                    </Card>
                    </NavLink>
                </Col>
                <Col>
                    <Card className="card-stats">
                        <Card.Body>
                            <Row>
                                <Col xs="5">
                                    <div className="icon-big text-center icon-warning">
                                        <i className="nc-icon nc-chart text-warning"></i>
                                    </div>
                                </Col>
                                <Col xs="7">
                                    <div className="numbers">
                                        <Card.Title as="h4">Reports</Card.Title>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer>
                            <hr></hr>
                            <div className="stats">
                                <i className="fas fa-redo mr-1"></i>
                                Download
                            </div>
                        </Card.Footer>
                    </Card>
                </Col>

            </Row>

        </Container>

    );
}

export default GkTab;