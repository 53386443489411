
import React, { useState, useEffect } from 'react';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import API from '../../utils/apiCalling'
import { config } from '../../utils/apiUrl'
import { validation } from '../../utils/configCommon';
import { setLocalDataAsObject, getLocalDataAsObject, clearLocalData } from '../../utils/CoustomStorage';
import { useHistory } from "react-router-dom";
import './login.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import swal from "sweetalert";
const Login = (props) => {
    const { } = props
    let api = new API()
    let history = useHistory();
    const [user, setUser] = useState({
        email: "",
        password: ""
    })

    useEffect(() => {
        tokenVerify()
    }, [])

    const notify = (message) => toast(message);
    
    const tokenVerify = async() => {
        // alert("inside token verify")
        let result = await api.get(config.token_verify)
        // console.log("successsssssssFaileddddd", result)

        if(result && result.code == 200) {
            history.push('/admin/dashboard')
        } else {
            // console.log("inside else clear token")
            clearLocalData()
        }
    }
    const onChangeFun = (e) => {
        const { name, value } = e.target
        // console.log(name, value)
        setUser({
            ...user,
            [name]: value
        })
    }
    const submitLogin = async () => {
        swal({
            html: true,
            title: "Thank You!,",
            text: `Are you sure to login`,
            icon: "success",
            buttons: ["No, cancel it!", "Login"],
            dangerMode: true,
          }).then(async function (isConfirm) {
            if (isConfirm) {
                let data = {
                    email: user.email,
                    password: user.password
                }
                console.log("data is ", data)
                // return
                let result = await api.post(config.login, data)
                console.log("login result is", result)
                if (result && result.token) {
                    notify(`Hi, Thanks for login!`)
                    setLocalDataAsObject("token", result.token)
                    // history.push('/admin')
                    tokenVerify()
                } else {
                    notify(`Hi, Unable to login!`)
            }
            }
          }
          )
       
}
    return (
        <div className="container mt-3" style={{ minHeight: "700px" }}>

            <div className="container px-5 px-md-5 px-lg-1 px-xl-5 py-5 mx-auto main" >
                <div className="card card0 border-0" >
                    <div className="row d-flex">
                        <div className="col-lg-6">
                            <div className="card1">
                                <div className="">
                                    {/* <img src="images/gkknow.png" className="logo" /> */}
                                </div>
                                <div className="text-center mt-4  border-line">
                                    {/* <img src="images/gkknow.png" className="image" /> */}
                                    <img src={"/images/gkknow.png"} alt="..." width={300} height={150} />
                                    <h2 style={{ color: "#3c4b64" }}>Admin Dashboard</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6" >
                            <div className="card2 card border-0 px-4 py-5">
                                <AvForm onValidSubmit={submitLogin} className="row">
                                    <div className="col-lg-12">
                                        <h4 className="text-center mb-0 uppercase">User Login</h4>
                                    </div>

                                    <div className="col-lg-12 custom mb-0">
                                        <div className="col-lg-12 custom mb-0">
                                            <span className="input-group-text bg-transparent  border border-0">
                                                <i className="fa fa-envelope" aria-hidden="true" /> &nbsp;
                                                <span>Email</span>
                                            </span>
                                            <AvField type="email" name="email" className="form-control bg-transparent validate"
                                                value={user.email} onChange={onChangeFun} placeholder="Enter Email..."
                                                validate={{
                                                    required: {
                                                        value: true,
                                                        errorMessage: "Email is required",
                                                    },
                                                    pattern: {
                                                        value: validation.email,
                                                        errorMessage:
                                                            "Email should be correct",
                                                    },

                                                }} />
                                        </div>
                                    </div>
                                    <div className="col-lg-12  custom mb-0">
                                        <div className="col-lg-12 custom mb-0">
                                            <span className="input-group-text bg-transparent  border border-0">
                                                <i className="fa fa-envelope" aria-hidden="true" /> &nbsp;
                                                <span>Email</span>
                                            </span>
                                            <AvField type="password" name="password" className="form-control bg-transparent"
                                                value={user.password} onChange={onChangeFun} placeholder="Enter password..."
                                                validate={{
                                                    required: {
                                                        value: true,
                                                        errorMessage: "password is required",
                                                    },
                                                }} />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 text-center text-center mt-3">
                                        <button type="submit" className="btn btn-success" >
                                            {/* <i className="fa fa-email" aria-hidden="true" /> */}
                                             Submit</button>
                                    </div>
                                </AvForm>
                            </div>
                        </div>



                    </div>
                    <div className="text-center py-4 text-white" style={{ backgroundColor: "#262626" }}>
                        <h5>www.gkknow.com</h5>
                    </div>
                </div>
            </div>
            <ToastContainer />
            
        </div>
    );
}

export default Login;