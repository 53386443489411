
import React, { useState, useEffect } from 'react';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import API from '../../../utils/apiCalling'
import { config } from '../../../utils/apiUrl'
import { validation } from '../../../utils/configCommon';
import { setLocalDataAsObject, getLocalDataAsObject } from '../../../utils/CoustomStorage';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import swal from "sweetalert";
import './get_gk.css'
import {
    CNav,
    CNavItem,
    CNavLink,
    CRow,
    CTabContent,
    CTabPane,
    CModal,
    CTabs,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CBadge,
    CDataTable,
    CButton,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCol,
    CForm,
    CSelect,
    CFormGroup,
    CInputGroupAppend,
    CInputGroup,
    CLabel,
    CInputCheckbox,
    CSwitch,
    CFormText,
    CInput,
    CInputFile,
} from '@coreui/react'
const GetGkMultipleChoiceQuestion = (props) => {
    const { deleteQuestion, getAllQuestions, toggle, selectedGkType, statusActiveInactive } = props
    const [active, setActive] = useState(0)

    const [searchText, setSearchText] = useState("")

    useEffect(() => {
        
        console.log(searchText)
    }, [searchText])
    const notify = (message) => toast(message);

    const deleteQueFunCall = (que) => {
        deleteQuestion(que)
    }
    const editQueFunCall = (que) => {
        toggle(que)
    }

    const makeActiveInactive = (que) => {
        statusActiveInactive(que)
    }
    return (
        <div className="container-fluid mt-3" style={{ minHeight: "700px" }}>

            <div className="card">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-5">
                            {selectedGkType && selectedGkType} Question Answer
                        </div>
                        <div className="col-md-7 align-right     ">
                            <div className="container ">
                                <div className="d-flex float-right justify-content-center">
                                    <div className="searchbar">
                                        <input className="search_input" type="text" name="" placeholder="Search..." onChange={(e) => setSearchText(e.target.value)} />
                                        <a href="#" className="search_icon"><i class="fas fa-search"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <CTabs activeTab={active} onActiveTabChange={idx => setActive(idx)}>
                        <CNav variant="tabs">
                            <CNavItem>
                                <CNavLink>
                                    All Questions
                                </CNavLink>
                            </CNavItem>
                            <CNavItem>
                                <CNavLink>
                                    Inactive Questions
                                </CNavLink>
                            </CNavItem>
                            <CNavItem>
                                <CNavLink>
                                    Deleted Questions
                                </CNavLink>
                            </CNavItem>
                        </CNav>
                        <CTabContent>
                            <CTabPane>
                                {getAllQuestions && getAllQuestions.length > 0 &&
                                    getAllQuestions.filter((val) => {
                                        if (!searchText) {
                                            return val
                                        } else if (val.question.toLowerCase().includes(searchText.toLowerCase())) {
                                            return val
                                        }
                                    }).map((que, index) => (

                                        <div className="card">
                                            <div className="card-header" style={{ backgroundColor: "#6f4e37" }}>
                                                {index + 1} {que.question}
                                                <button onClick={() => makeActiveInactive(que)} className={que.status == "inactive" ? "float-right btn btn-danger" : " float-right btn btn-success"}>
                                                    {que.status ? que.status : "None"}
                                                </button>
                                            </div>
                                            <ul class="list-group">
                                                {console.log("takeeeeeeeeeeeeeeeeeeeeeeeee", getAllQuestions)}
                                                { que.options.length > 0 && que.options.map(op => (
                                                    <li class="list-group-item">{op.option}</li>
                                                ))
                                                }
                                                {/* <li class="list-group-item">{que.option1}</li>
                                                <li class="list-group-item">{que.option2}</li>
                                                <li class="list-group-item">{que.option3}</li>
                                                <li class="list-group-item">{que.option4}</li> */}
                                                <li class="list-group-item list-group-item-info">{que.answer}</li>
                                            </ul>
                                            <div className="card-body">
                                            </div>
                                            <div className="card-footer">
                                                <button className="btn btn-primary" onClick={() => editQueFunCall(que)}>Edit</button>
                                                <button className="btn btn-danger ml-2" onClick={() => deleteQueFunCall(que)}>Delete</button>
                                            </div>
                                        </div>
                                    ))}
                            </CTabPane>
                            <CTabPane>
                                {getAllQuestions && getAllQuestions.length > 0 &&
                                    getAllQuestions.filter((val) => {
                                        if (!searchText && val.status == "inactive") {
                                            return val
                                        } else if (val.question.toLowerCase().includes(searchText.toLowerCase()) && val.status == "inactive") {
                                            return val
                                        }
                                    }).map((que, index) => (
                                        <div className="card">
                                            <div className="card-header" style={{ backgroundColor: "#6f4e37" }}>
                                                {index + 1} {que.question}
                                                <button onClick={makeActiveInactive} className={que.status == "inactive" ? "float-right btn btn-danger" : " float-right btn btn-success"}>
                                                    {que.status ? que.status : "None"}
                                                </button>
                                            </div>
                                            <ul class="list-group">
                                            { que.options.length > 0 && que.options.map(op => (
                                                    <li class="list-group-item">{op.option}</li>
                                                ))
                                                }
                                                <li class="list-group-item list-group-item-info">{que.answer}</li>
                                            </ul>
                                            <div className="card-body">
                                            </div>
                                            <div className="card-footer">
                                                <button className="btn btn-primary" onClick={() => editQueFunCall(que)}>Edit</button>
                                                <button className="btn btn-danger ml-2" onClick={() => deleteQueFunCall(que)}>Delete</button>
                                            </div>
                                        </div>
                                    ))}
                            </CTabPane>
                            <CTabPane>
                                {getAllQuestions && getAllQuestions.length > 0 &&
                                    getAllQuestions.filter((val) => {
                                        if (!searchText && val.status == "delete") {
                                            return val
                                        } else if (val.question.toLowerCase().includes(searchText.toLowerCase()) && val.status == "delete") {
                                            return val
                                        }
                                    }).map((que, index) => (
                                        <div className="card">
                                            <div className="card-header" style={{ backgroundColor: "#6f4e37" }}>
                                                {index + 1} {que.question}
                                                <button onClick={makeActiveInactive} className={que.status == "inactive" ? "float-right btn btn-danger" : " float-right btn btn-success"}>
                                                    {que.status ? que.status : "None"}
                                                </button>
                                            </div>
                                            <ul class="list-group">
                                            { que.options.length > 0 && que.options.map(op => (
                                                    <li class="list-group-item">{op.option}</li>
                                                ))
                                                }
                                                <li class="list-group-item list-group-item-info">{que.answer}</li>
                                            </ul>
                                            <div className="card-body">
                                            </div>
                                            <div className="card-footer">
                                                <button className="btn btn-primary" onClick={() => editQueFunCall(que)}>Edit</button>
                                                <button className="btn btn-danger ml-2" onClick={() => deleteQueFunCall(que)}>Delete</button>
                                            </div>
                                        </div>
                                    ))}
                            </CTabPane>
                        </CTabContent>
                    </CTabs>
                </div>
            </div>

            <ToastContainer />
        </div>
    );
}


export default GetGkMultipleChoiceQuestion;