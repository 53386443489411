
import React, { useState, useEffect } from 'react';
import API from '../../utils/apiCalling'
import { useHistory } from "react-router-dom";
import './index.css'
import GkTab from './tab';


const GeneralKnowledgeDashboard = () => {



    return (
        <div>
            <GkTab />
        </div>
    );
}

export default GeneralKnowledgeDashboard;