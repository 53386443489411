
import React, { useState, useEffect } from 'react';
import {
    CNav,
    CNavItem,
    CNavLink,
    CRow,
    CTabContent,
    CTabPane,
    CModal,
    CTabs,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CBadge,
    CDataTable,
    CButton,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCol,
    CForm,
    CSelect,
    CFormGroup,
    CInputGroupAppend,
    CInputGroup,
    CLabel,
    CInputCheckbox,
    CSwitch,
    CFormText,
    CInput,
    CInputFile,
} from '@coreui/react'
import API from 'utils/apiCalling';
import { config } from 'utils/apiUrl';
import swal from "sweetalert";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalSeo from './modal';
const SeoManagement = (props) => {
    const { } = props
    const api = new API()
    const [active, setActive] = useState(0)
    const [allUser, setAllUser] = useState()
    const [modal, setModal] = useState(false)
    const [getAllUrlItem, setGetAllUrlItem] = useState()
    const [selectedItem, setSelectedItem] = useState()
    const [isViewItemSection, setIsViewItemSection] = useState(false)
    const [selectedForAddSeo, setSelectedForAddSeo] = useState()

    useEffect(() => {
        getUrlItem()
    }, [])
    const notify = (message) => toast(message);
    const getUrlItem = async () => {
        let result = await api.get(config.getAllUrlItem)
        if (result && result.code == 200) {
            // alert("successfully getting user")
            setGetAllUrlItem(result.data)
        } else {
            console.log("unable to get user")
        }
    }
    // const editUser = async () => {
    //     swal({
    //         html: true,
    //         title: "Thank You!,",
    //         text: `Are you sure to edit User`,
    //         icon: "success",
    //         buttons: ["No, cancel it!", "Edit"],
    //         dangerMode: true,
    //     }).then(async function (isConfirm) {
    //         if (isConfirm) {
    //             alert("user editing...")
    //         }
    //     })
    // }
    // const deleteUser = async (user) => {
    //     swal({
    //         html: true,
    //         title: "Thank You!,",
    //         text: `Are you sure to delete User`,
    //         icon: "success",
    //         buttons: ["No, cancel it!", "Delete"],
    //         dangerMode: true,
    //     }).then(async function (isConfirm) {
    //         if (isConfirm) {
    //             let data = {
    //                 id: user._id
    //             }
    //             console.log(data)
    //             let result = await api.post(config.deleteUser, data)
    //             if (result && result.code == 200) {
    //                 toast.success("deleted")
    //                 getUser()
    //             } else {
    //                 notify(result && result.message)
    //             }
    //         }
    //     })
    // }
    // const statusActiveInactive = (user) => {
    //     let status = user.status == "active" ? "inactive" : "active";
    //     let data = {
    //         id: user._id,
    //         status : status
    //     }
    //     console.log(data)
    //     swal({
    //         html: true,
    //         title: "Thank You!,",
    //         text: `Are you sure to ${status} user`,
    //         icon: "success",
    //         buttons: ["No, cancel it!", "Submit"],
    //         dangerMode: true,
    //     }).then(async function (isConfirm) {
    //         if (isConfirm) {
    //             let result = await api.post(config.userStatusActiveInactive, data)
    //             if (result && result.code == 200) {
    //                 console.log("user status", result)
    //                 toast.success(result.message)
    //                 getUser()
    //             }
    //             else {
    //                 toast.success(result && result.code == 200)
    //             }
    //         }
    //     }
    //     )
    // }
    const isViewSelected = (item) => {
        setSelectedItem(item)
        setIsViewItemSection(!isViewItemSection)
    }
    const toggle = (item) => {
        setSelectedForAddSeo(item)
        setModal(!modal)
    }
    return (
        <div className="container-fluid">
            <CRow>
                <CCol xs="12" >
                    <CCard>
                        <CCardHeader>
                            SEO MANAGEMENT
                            <button className="btn btn-success mr-2 float-right" onClick={toggle}>Add New Category</button>
                        </CCardHeader>
                        <CCardBody>
                            {isViewItemSection == false ?
                                <CTabs activeTab={active} onActiveTabChange={idx => setActive(idx)}>
                                    <CNav variant="tabs">
                                        <CNavItem>
                                            <CNavLink>
                                                All CATEGORY ITEM
                                            </CNavLink>
                                        </CNavItem>
                                    </CNav>
                                    <CTabContent>
                                        <CTabPane>
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Id</th>
                                                        <th scope="col">type</th>
                                                        <th scope="col">status</th>
                                                        {/* <th scope="col">Delete</th> */}
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {getAllUrlItem && getAllUrlItem.length > 0 && getAllUrlItem.map((item, index) => (
                                                        <tr>
                                                            <td>{item._id}</td>
                                                            <td>{item.type}</td>
                                                            <td>{item.status}</td>
                                                            {/* <td>{user.date}</td> */}
                                                            <td>
                                                                <button className="btn btn-danger" onClick={() => isViewSelected(item)}>View All Item</button>
                                                            </td>
                                                            <td>
                                                                {/* <button className={user.status == "active" ? "btn btn-success" : "btn btn-danger"}>{user.status ? user.status : "None"}</button> */}
                                                            </td>
                                                        </tr>
                                                    ))}

                                                </tbody>
                                            </table>

                                        </CTabPane>

                                    </CTabContent>

                                </CTabs>
                                :
                                <div>
                                    {/* <h4>is view selected item section</h4> */}
                                    <CTabs activeTab={active} onActiveTabChange={idx => setActive(idx)}>
                                        <CNav variant="tabs">
                                            <CNavItem>
                                                <CNavLink>
                                                    { selectedItem.type}
                                                </CNavLink>
                                            </CNavItem>
                                        </CNav>
                                        <CTabContent>
                                            <CTabPane>
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            {/* <th scope="col">Id</th> */}
                                                            <th scope="col">Route Value</th>
                                                            <th scope="col">Route URL</th>
                                                            <th scope="col">Action</th>
                                                            {/* <th scope="col">Edit</th>
                                                            <th scope="col">Delete</th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {selectedItem && selectedItem.item.length > 0 && selectedItem.item.map((url_item, index) => (
                                                            <tr>
                                                                {/* <td>{url_item._id}</td> */}
                                                                <td>{url_item.value}</td>
                                                                <td>{url_item.routeName}</td>
                                                                {/* <td>{user.date}</td> */}

                                                                <td>
                                                                    <button className="btn btn-primary" onClick={()=>toggle(url_item)}>Add SEO</button>
                                                                </td>
                                                                {/* <td>
                                                                    <button className="btn btn-info" >Edit</button>
                                                                </td>
                                                                <td>
                                                                    <button className="btn btn-danger" >Delete</button>
                                                                </td> */}
                                                                <td>
                                                                    {/* <button className={user.status == "active" ? "btn btn-success" : "btn btn-danger"}>{user.status ? user.status : "None"}</button> */}
                                                                </td>
                                                            </tr>
                                                        ))}

                                                    </tbody>
                                                </table>
                                            </CTabPane>

                                        </CTabContent>

                                    </CTabs>
                                </div>
                            }
                            <CCardFooter>
                                {isViewItemSection &&
                                    <button onClick={() => setIsViewItemSection(false)} className={"btn btn-info"}>Back</button>
                                }
                            </CCardFooter>

                        </CCardBody>
                    </CCard>

                </CCol>
            </CRow>
            <ModalSeo modal={modal} toggle={toggle}
                selectedForAddSeo={selectedForAddSeo}
                // getUser={getUser} 
                />
            <ToastContainer />
        </div >
    );
}

export default SeoManagement;