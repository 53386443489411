import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import KendoTextEditor from 'views/common/kendo_text_editor';


const ModalEditBlog = (props) => {
    const { modal, toggle, editBlog, blogForEdit } = props;
    // const [otp, setOtp] = useState()
    const [imageUrl, setImageUrl] = useState("https://vijay.gkknow.com/gkknow_blog_images")
    const [content, setContent] = useState("")
    const [blog, setBlog] = useState()
    useEffect(() => {
        console.log("question for edit is........", blogForEdit)
        setContent(blogForEdit && blogForEdit.blog_content,)
        setBlog(blogForEdit && blogForEdit)
    }, [blogForEdit])
    const onChangeFun = (e) => {
        const { name, value } = e.target
        setBlog({
            ...blog,
            [name]: value
        })
    }
    const editBlogFun = () => {
        blog.id = blogForEdit._id
        blog.blog_content = content
        console.log(blog)
        editBlog(blog)
    }
    return (
        <div>
            <Modal isOpen={modal} toggle={toggle}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <ModalHeader toggle={toggle} className="primary">modal heading <span></span></ModalHeader>
                <ModalBody>

                    <div className="container">
                        <AvForm onValidSubmit={editBlogFun} className="row">
                            <div className="col-lg-12">
                                {/* <h4 className="text-center mb-0 uppercase">Fill details for add question</h4> */}
                            </div>


                            <div className="col-lg-12 input-group custom mb-0">
                                <div className="col-lg-12 custom mb-0">
                                    <span>Blog Heading</span>
                                    <AvField className="form-control bg-transparent" type="text" name="blog_heading"
                                        value={blog && blog.blog_heading} onChange={onChangeFun} />
                                </div>
                            </div>

                            <div className="col-lg-12 input-group custom mb-0">
                                <div className="col-lg-12 custom mb-0">
                                    <span>Blog Tag</span>
                                    <AvField className="form-control bg-transparent" type="text" name="blog_tag"
                                        value={blog && blog.blog_tag} onChange={onChangeFun} />
                                </div>
                            </div>
                            <div className="col-lg-12 input-group custom mb-0">
                                <div className="col-lg-12 custom mb-0">
                                    <span>blog_sub_heading</span>
                                    <AvField className="form-control bg-transparent" type="text" name="blog_sub_heading"
                                        value={blog && blog.blog_sub_heading} onChange={onChangeFun} />
                                </div>
                            </div>
                            <div className="col-lg-12 input-group custom mb-0">
                                <div className="col-lg-12 custom mb-0">
                                    <span>blog_content</span>
                                    {/* <AvField className="form-control bg-transparent" type="text" name="blog_content"
                                        value={blog.blog_content} onChange={onChangeFun} /> */}

                                        <KendoTextEditor content={content} setContent={setContent}/>
                                </div>
                            </div>
                            <div className="col-lg-12 input-group custom mb-0">
                                <div className="col-lg-12 custom mb-0">
                                    <span>blog_sub_content_1</span>
                                    <AvField className="form-control bg-transparent" type="text" name="blog_sub_content_1"
                                        value={blog && blog.blog_sub_content_1} onChange={onChangeFun} />
                                </div>
                            </div>
                            <div className="col-lg-12 input-group custom mb-0">
                                <div className="col-lg-12 custom mb-0">
                                    <span>blog_sub_content_2</span>
                                    <AvField className="form-control bg-transparent" type="text" name="blog_sub_content_2"
                                        value={blog && blog.blog_sub_content_2} onChange={onChangeFun} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md">
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Blog image</label><br></br>
                                        <img src={`${imageUrl}/${blog && blog.image1}`} alt="..." width={300} height={150} />
                                    </div> 
                                </div>
                                <div className="col-md">
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Blog sub image</label><br></br>
                                        <img src={`${imageUrl}/${blog && blog.image2}`} alt="..." width={300} height={150} />
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-12 text-center text-center mt-3">
                                <button type="submit" className="btn btn-success btn-block rounded-0 button-room" >
                                    <i className="fa fa-plus" aria-hidden="true" />Edit Blog </button>
                            </div>
                        </AvForm>
                    </div>
                </ModalBody>
            </Modal >
        </div>
    );
}

export default ModalEditBlog;