
import React, { useState, useEffect } from 'react';
import {
    CNav,
    CNavItem,
    CNavLink,
    CRow,
    CTabContent,
    CTabPane,
    CModal,
    CTabs,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CBadge,
    CDataTable,
    CButton,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCol,
    CForm,
    CSelect,
    CFormGroup,
    CInputGroupAppend,
    CInputGroup,
    CLabel,
    CInputCheckbox,
    CSwitch,
    CFormText,
    CInput,
    CInputFile,
} from '@coreui/react'
import API from 'utils/apiCalling';
import { config } from 'utils/apiUrl';
import swal from "sweetalert";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalUser from './modal';
const GetUser = (props) => {
    const { } = props
    const api = new API()
    const [active, setActive] = useState(0)
    const [allUser, setAllUser] = useState()
    const [modal, setModal] = useState(false)
    const [selectedUser, setSelectedUser] = useState()

    useEffect(() => {
        getUser()
    }, [])
    const notify = (message) => toast(message);
    const getUser = async () => {
        let result = await api.get(config.getUser)
        if (result && result.code == 200) {
            // alert("successfully getting user")
            setAllUser(result.data)
        } else {
            console.log("unable to get user")
        }
    }
    // const editUser = async () => {
    //     swal({
    //         html: true,
    //         title: "Thank You!,",
    //         text: `Are you sure to edit User`,
    //         icon: "success",
    //         buttons: ["No, cancel it!", "Edit"],
    //         dangerMode: true,
    //     }).then(async function (isConfirm) {
    //         if (isConfirm) {
    //             alert("user editing...")
    //         }
    //     })
    // }
    const deleteUser = async (user) => {
        swal({
            html: true,
            title: "Thank You!,",
            text: `Are you sure to delete User`,
            icon: "success",
            buttons: ["No, cancel it!", "Delete"],
            dangerMode: true,
        }).then(async function (isConfirm) {
            if (isConfirm) {
                let data = {
                    id: user._id
                }
                console.log(data)
                let result = await api.post(config.deleteUser, data)
                if (result && result.code == 200) {
                    toast.success("deleted")
                    getUser()
                } else {
                    notify(result && result.message)
                }
            }
        })
    }
    const statusActiveInactive = (user) => {
        let status = user.status == "active" ? "inactive" : "active";
        let data = {
            id: user._id,
            status : status
        }
        console.log(data)
        swal({
            html: true,
            title: "Thank You!,",
            text: `Are you sure to ${status} user`,
            icon: "success",
            buttons: ["No, cancel it!", "Submit"],
            dangerMode: true,
        }).then(async function (isConfirm) {
            if (isConfirm) {
                let result = await api.post(config.userStatusActiveInactive, data)
                if (result && result.code == 200) {
                    console.log("user status", result)
                    toast.success(result.message)
                    getUser()
                }
                else {
                    toast.success(result && result.code == 200)
                }
            }
        }
        )
    }
    const toggle = (user) => {
        setSelectedUser(user)
        setModal(!modal)
    }
    return (
        <div className="container-fluid">
            <CRow>
                <CCol xs="12" >
                    <CCard>
                        <CCardHeader>
                            USER MANAGEMENT
                            <button className="btn btn-success mr-2 float-right" onClick={toggle}>Add New User</button>
                        </CCardHeader>
                        <CCardBody>
                            <CTabs activeTab={active} onActiveTabChange={idx => setActive(idx)}>
                                <CNav variant="tabs">
                                    <CNavItem>
                                        <CNavLink>
                                            All USER ACCOUNTS
                                        </CNavLink>
                                    </CNavItem>
                                </CNav>
                                <CTabContent>
                                    <CTabPane>
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Id</th>
                                                    <th scope="col">First Name</th>
                                                    <th scope="col">Last Name</th>
                                                    <th scope="col">Mobile</th>
                                                    <th scope="col">Email</th>
                                                    <th scope="col">Created By</th>
                                                    <th scope="col">Date</th>
                                                    <th scope="col">Delete</th>
                                                    <th scope="col">Action</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {allUser && allUser.length > 0 && allUser.map((user, index) => (
                                                    <tr>
                                                        <td>{user._id}</td>
                                                        <td>{user.firstname}</td>
                                                        <td>{user.lastname}</td>
                                                        <td>{user.mobile}</td>
                                                        <td>{user.email}</td>
                                                        <td>{user.created_by}</td>
                                                        <td>{user.date}</td>

                                                        <td>
                                                            <button className="btn btn-danger" onClick={() => deleteUser(user)}>Delete</button>
                                                        </td>
                                                        <td>
                                                            <button className={user.status == "active" ? "btn btn-success" : "btn btn-danger"} onClick={() => statusActiveInactive(user)}>{user.status ? user.status : "None"}</button>
                                                        </td>
                                                    </tr>
                                                ))}

                                            </tbody>
                                        </table>

                                    </CTabPane>

                                </CTabContent>

                            </CTabs>
                            <CCardFooter>
                                {/* <CButton color="success" >Back</CButton>{' '} */}
                            </CCardFooter>

                        </CCardBody>
                    </CCard>

                </CCol>
            </CRow>
            <ModalUser modal={modal} toggle={toggle}
                selectedUser={selectedUser}
                getUser={getUser} />
            <ToastContainer />
        </div >
    );
}

export default GetUser;