
import React, { useState, useEffect } from 'react';
import API from '../../utils/apiCalling'
import { config } from '../../utils/apiUrl';
import { ToastContainer, toast } from 'react-toastify'
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
// import 'react-toastify/dist/ReactToastify.css';
import swal from "sweetalert";

const AddUser = (props) => {
    const { selectedUser, toggle, getUser } = props
    const api = new API()
    const [newUser, setNewUser] = useState({
        firstname: "",
        lastname: "",
        mobile: "",
        email: "",
        password: "",
    })
    // const notify = (message) => toast(message);

    useEffect(() => {
        
    }, [])
    const onChangeFun = (e) => {
        let { name, value } = e.target
        setNewUser({
            ...newUser,
            [name]: value
        })
    }
    const addUserFun = () => {
        let data = newUser
        swal({
            html: true,
            title: "Thank You!,",
            text: `Are you sure to add new user`,
            icon: "success",
            buttons: ["No, cancel it!", "Add"],
            dangerMode: true,
        }).then(async function (isConfirm) {
            if (isConfirm) {
                let result = await api.post(config.signup, data)
                if(result && result.code == 200){
                    console.log(newUser)
                    toast.success(`Hi, Thanks for adding new user!`)
                    toggle()
                    getUser()
                } else {
                    toast.success(result && result.message)
                }
            }
        })
    }
    return (
        <div style={{ margin: "auto" }}>
            <div className="container">
                <AvForm onValidSubmit={addUserFun} className="row">
                    <div className="col-lg-12">
                        <h4 className="text-center mb-0 uppercase">Details for new user</h4>
                    </div>
                    <div className="col-lg-12 input-group custom mb-0">
                        <div className="col-lg-12 custom mb-0">
                            <span>First Name</span>
                            <AvField className="form-control bg-transparent" type="text" name="firstname"
                                value={newUser && newUser.firstname} onChange={onChangeFun}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: "Please Enter firstname",
                                    },
                                }}
                                placeholder="Enter firstname..." />
                        </div>
                    </div>
                    <div className="col-lg-12 input-group custom mb-0">
                        <div className="col-lg-12 custom mb-0">
                            <span>Last Name</span>
                            <AvField className="form-control bg-transparent" type="text" name="lastname"
                                value={newUser && newUser.lastname} onChange={onChangeFun}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: "Please Enter Last Name",
                                    },

                                }}
                                placeholder="Enter Last Name..." />
                        </div>
                    </div>
                    <div className="col-lg-12 input-group custom mb-0">
                        <div className="col-lg-12 custom mb-0">
                            <span>Email</span>
                            <AvField className="form-control bg-transparent" type="text" name="email"
                                value={newUser && newUser.email} onChange={onChangeFun}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: "Please Enter Email",
                                    },

                                }}
                                placeholder="Enter Email..." />
                        </div>
                    </div>
                    <div className="col-lg-12 input-group custom mb-0">
                        <div className="col-lg-12 custom mb-0">
                            <span>Mobile</span>
                            <AvField className="form-control bg-transparent" type="text" name="mobile"
                                value={newUser && newUser.mobile} onChange={onChangeFun}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: "Please Enter mobile",
                                    },

                                }}
                                placeholder="Enter mobile..." />
                        </div>
                    </div>
                    <div className="col-lg-12 input-group custom mb-0">
                        <div className="col-lg-12 custom mb-0">
                            <span>Password</span>
                            <AvField className="form-control bg-transparent" type="text" name="password"
                                value={newUser && newUser.password} onChange={onChangeFun}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: "Please Enter Password",
                                    },

                                }}
                                placeholder="Enter Password..." />
                        </div>
                    </div>


                    <div className="col-lg-12 text-center text-center mt-3">
                        <button type="submit" className="btn btn-success btn-block rounded-0 button-room" >
                            <i className="fa fa-plus" aria-hidden="true" /> Add User</button>
                    </div>
                </AvForm>
            </div>
            {/* <ToastContainer /> */}
        </div>
    );
}

export default AddUser;