import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import { MultiSelect } from "react-multi-select-component";

const ModalEdit = (props) => {
    const { modal, toggle, editQuestion, questionForEdit, allNavItem, selectGkCategoryTypeForAdditionalCategory, selectedSubCategoryAdditionalCat, selected, setSelected, deleteAdditionalCat } = props;
    // const [otp, setOtp] = useState()
    const [question, setQuestion] = useState({
        question: "",
        answer: ""
    })
    useEffect(() => {
        console.log("question for edit is........", questionForEdit)
        setQuestion({
            ...question,
            question: questionForEdit && questionForEdit.question,
            answer: questionForEdit && questionForEdit.answer
        })
        let filterVal = []
        questionForEdit && questionForEdit.additional_type.length>0 && questionForEdit.additional_type.filter(val=>{
            if(val){
                filterVal.push({"label" : val, "value" : val, "name" : val})
            }
        })
        setSelected([...filterVal])
    }, [questionForEdit])
    const onChangeFun = (e) => {
        const { name, value } = e.target
        setQuestion({
            ...question,
            [name]: value
        })
    }
    const editQueFun = ()=>{
        editQuestion(question)
    } 
    return (
        <div>
            <Modal isOpen={modal} toggle={toggle}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter">
                <ModalHeader toggle={toggle} className="primary">modal heading <span></span></ModalHeader>
                <ModalBody>

                    <div className="container">
                        <AvForm onValidSubmit={editQueFun} className="row">
                            <div className="col-lg-12">
                                {/* <h4 className="text-center mb-0 uppercase">Fill details for add question</h4> */}
                            </div>


                            <div className="col-lg-12 input-group custom mb-0">
                                <div className="col-lg-12 custom mb-0">
                                    {/* <span className="input-group-text bg-transparent  border border-0 text-white">
        <i className="fa fa-mobile" aria-hidden="true" /> &nbsp; */}
                                    <span>Question</span>
                                    {/* </span> */}

                                    <AvField className="form-control bg-transparent" type="text" name="question"
                                        value={question.question} onChange={onChangeFun}
                                        validate={{
                                            required: {
                                                value: true,
                                                errorMessage: "Please Enter Question",
                                            },
                                            minLength: {
                                                value: 1,
                                                errorMessage: "Question Lenght should be more than 10",
                                            },
                                            maxLength: {
                                                value: 1000,
                                                errorMessage: "Question Lenght more than limit",
                                            },
                                        }}
                                        placeholder="Enter Question..." />
                                </div>
                            </div>
                            <div className="col-lg-12 input-group custom mb-0">
                                <div className="col-lg-12 custom mb-0">
                                    {/* <span className="input-group-text bg-transparent  border border-0 text-white">
        <i className="fa fa-mobile" aria-hidden="true" /> &nbsp; */}
                                    <span>Answer</span>
                                    {/* </span> */}

                                    <AvField className="form-control bg-transparent" type="text" name="answer"
                                        value={question.answer} onChange={onChangeFun}
                                        validate={{
                                            required: {
                                                value: true,
                                                errorMessage: "Please Enter Answer",
                                            },
                                            minLength: {
                                                value: 1,
                                                errorMessage: "Answer Lenght should be more than 10",
                                            },
                                            maxLength: {
                                                value: 1000,
                                                errorMessage: "Answer Lenght more than limit",
                                            },
                                        }}
                                        placeholder="Enter Answer..." />
                                </div>
                            </div>
                            <div className="col-lg-12 input-group custom mb-0">
                                <div className="col-lg-12 custom mb-0">
                                    <span>Select Additional Category</span>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className='mb-2'>
                                                <select className="form-control" onChange={selectGkCategoryTypeForAdditionalCategory}>
                                                    <option value="">Add GK For</option>
                                                    {allNavItem.length > 0 && allNavItem.map((navItem, index) => (
                                                        <option key={index} value={index}>{navItem.name}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <MultiSelect
                                                options={selectedSubCategoryAdditionalCat.map(val => {
                                                    val.label = val.name
                                                    return val
                                                })}
                                                value={selected}
                                                onChange={setSelected}
                                                labelledBy="Select"
                                            />
                                        </div>
                                        <div className='col-md-6'>
                                            {selected.length > 0 && selected.map(val => (
                                                <button type="button" className='btn btn-info btn-sm rounded-pill p-2 mr-1'>{val.name} <i onClick={() => deleteAdditionalCat(val.value)} style={{ cursor: "pointer", color: "red" }} class="fa fa-trash" aria-hidden="true"></i></button>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-12 text-center text-center mt-3">
                                <button type="submit" className="btn btn-success btn-block rounded-0 button-room" >
                                    <i className="fa fa-plus" aria-hidden="true" /> Edit Question </button>
                            </div>
                        </AvForm>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default ModalEdit;