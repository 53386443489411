
import React, { useState, useEffect } from 'react';
import API from '../../utils/apiCalling'
import { config } from '../../utils/apiUrl';
import { ToastContainer, toast } from 'react-toastify'
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
// import 'react-toastify/dist/ReactToastify.css';
import swal from "sweetalert";

const AddSeo = (props) => {
    const { selectedForAddSeo, toggle } = props
    const api = new API()
    const [seoAddedAlready, setSeoAddedAlready] = useState(false)
    const [seoDetails, setSeoDetails] = useState({
        title: "",
        meta_title: "",
        meta_description: "",
        meta_keywords: "",
        meta_image: "",
        meta_url: "",
    })
    // const notify = (message) => toast(message);

    useEffect(() => {
        // reset()
        getSeoIfAdded()
    }, [selectedForAddSeo])
    const onChangeFun = (e) => {
        let { name, value } = e.target

        setSeoDetails({
            ...seoDetails,
            [name]: value
        })
    }
    const getSeoIfAdded = async () => {
        let data = {
            routeName: selectedForAddSeo && selectedForAddSeo.routeName
        }
        let result = await api.get(config.getAddedSeo, true, data)
        if (result && result.code == 200 && result.data && result.data.length > 0) {
            setSeoAddedAlready(true)
            setSeoDetails(result.data[0])
        } else {
            setSeoAddedAlready(false)
            // reset()
            // toast.success(result && result.message)
        }
    }
    const deleteSeo = async () => {
        let data = {
            id: seoDetails && seoDetails._id
        }
        console.log(data)
        swal({
            html: true,
            title: "Thank You!,",
            text: `Are you sure to delete SEO details for ${selectedForAddSeo && selectedForAddSeo.routeName}`,
            icon: "success",
            buttons: ["No, cancel it!", "Delete"],
            dangerMode: true,
        }).then(async function (isConfirm) {
            if (isConfirm) {
                let result = await api.post(config.deleteSeo, data)
                if (result && result.code == 200) {
                    toast.success(result && result.message)
                    getSeoIfAdded()
                    reset()
                } else {
                    // reset()
                    // toast.success(result && result.message)
                }
            }
        })
    }

    const addSeoDetails = () => {
        let data = seoDetails
        data.tag_for = selectedForAddSeo.routeName
        console.log(data)
        swal({
            html: true,
            title: "Thank You!,",
            text: `Are you sure to add new user`,
            icon: "success",
            buttons: ["No, cancel it!", "Add"],
            dangerMode: true,
        }).then(async function (isConfirm) {
            if (isConfirm) {
                let result = await api.post(config.addSeo, data)
                if (result && result.code == 200) {
                    // console.log(seoDetails)
                    toast.success(`Hi, Thanks for adding new user!`)
                    toggle()
                    // getUser()
                } else {
                    toast.success(result && result.message)
                }
            }
        })
    }
    const reset = () => {
        setSeoDetails({
            title: "",
            meta_title: "",
            meta_description: "",
            meta_keywords: "",
            meta_image: "",
            meta_url: "",
        })
    }

    return (
        <div style={{ margin: "auto" }}>
            <div className="container card">
                <AvForm onValidSubmit={addSeoDetails} className="row">
                    <div className="col-lg-12">
                        <h4 className="text-center mb-0 text-uppercase">Add SEO For {selectedForAddSeo && selectedForAddSeo.value}</h4>
                    </div>
                    <div className="col-lg-12 input-group custom mb-0">
                        <div className="col-lg-12 custom mb-0">
                            <span>Title</span>
                            <AvField className="form-control bg-transparent" type="text" name="title"
                                value={seoDetails && seoDetails.title} onChange={onChangeFun}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: "Please enter title",
                                    },
                                }}
                                placeholder="Enter enter title..." />
                        </div>
                    </div>
                    <div className="col-lg-12 input-group custom mb-0">
                        <div className="col-lg-12 custom mb-0">
                            <span>Meta title</span>
                            <AvField className="form-control bg-transparent" type="text" name="meta_title"
                                value={seoDetails && seoDetails.meta_title} onChange={onChangeFun}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: "Please enter meta title",
                                    },
                                }}
                                placeholder="Enter enter meta title..." />
                        </div>
                    </div>
                    <div className="col-lg-12 input-group custom mb-0">
                        <div className="col-lg-12 custom mb-0">
                            <span>meta_description</span>
                            <AvField className="form-control bg-transparent" type="text" name="meta_description"
                                value={seoDetails && seoDetails.meta_description} onChange={onChangeFun}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: "Please enter meta description",
                                    },

                                }}
                                placeholder="Enter meta description..." />
                        </div>
                    </div>
                    <div className="col-lg-12 input-group custom mb-0">
                        <div className="col-lg-12 custom mb-0">
                            <span>Meta keywords</span>
                            <AvField className="form-control bg-transparent" type="text" name="meta_keywords"
                                value={seoDetails && seoDetails.meta_keywords} onChange={onChangeFun}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: "Please enter meta keywords",
                                    },

                                }}
                                placeholder="Enter meta_keywords" />
                        </div>
                    </div>
                    <div className="col-lg-12 input-group custom mb-0">
                        <div className="col-lg-12 custom mb-0">
                            <span>Meta URL</span>
                            <AvField className="form-control bg-transparent" type="text" name="meta_url"
                                value={seoDetails && seoDetails.meta_url} onChange={onChangeFun}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: "Please enter meta url",
                                    },

                                }}
                                placeholder="Enter meta url" />
                        </div>
                    </div>

                    <div className="col-lg-12 text-center text-center mt-3">
                        <button type="submit" className="btn btn-success rounded-0 button-room" >
                            <i className="fa fa-plus" aria-hidden="true" /> Submit SEO Details</button>

                    </div>
                </AvForm>
                <div className='card-footer' >
                    <button className="btn btn-danger m-2 float-right" style={seoAddedAlready ? {} : {display:"none"}} >
                        <i className="fa fa-trash" aria-hidden="true" onClick={deleteSeo} />
                    </button>
                    <button className="btn btn-info m-2 float-right" >
                        <i className="fas fa-sync" aria-hidden="true" onClick={reset} />
                    </button>

                </div>
            </div>
            <ToastContainer/>
        </div>
    );
}

export default AddSeo;