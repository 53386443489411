
import React, { useState, useEffect } from 'react';
import {
    CNav,
    CNavItem,
    CNavLink,
    CRow,
    CTabContent,
    CTabPane,
    CModal,
    CTabs,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CBadge,
    CDataTable,
    CButton,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCol,
    CForm,
    CSelect,
    CFormGroup,
    CInputGroupAppend,
    CInputGroup,
    CLabel,
    CInputCheckbox,
    CSwitch,
    CFormText,
    CInput,
    CInputFile,
} from '@coreui/react'
import QuizManagement from './QuizManagement';
const Quiz = (props) => {
    const { } = props

    useEffect(()=>{
        
    },[])
 
    return (
        <div className="container-fluid">
            <QuizManagement/>
        </div >
    );
}

export default Quiz;