
import React, { useState, useEffect } from 'react';
import API from '../../utils/apiCalling'
import { config } from '../../utils/apiUrl';
import { ToastContainer, toast } from 'react-toastify'
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
// import 'react-toastify/dist/ReactToastify.css';
import swal from "sweetalert";
import { Type } from '../../static/static'
import KendoTextEditor from 'views/common/kendo_text_editor';
import { CCollapse } from '@coreui/react'
// import 'react-toastify/dist/ReactToastify.css';
const AddQuizQuestion = (props) => {
    const { selectedQuiz, setIsAddQuizQuestion } = props
    const api = new API()
    const [content, setContent] = useState()
    const [quizImage, setQuizImage] = useState()
    const [questionOptions, setQuestionOptions] = useState([])
    const [getAllQuizQuestion, setGetAllQuizQuestion] = useState([])
    const [quizQuestion, setQuizQuestion] = useState({
        status: "active",
        type: "gk",
        question_type: "radio",
        question: "",
        option: "",
        answer: "",
    })
    const notify = (message) => toast(message);

    useEffect(() => {
        getQuizQuestion()
    }, [selectedQuiz])

    const onChangeFun = (e) => {
        let { name, value } = e.target
        setQuizQuestion({
            ...quizQuestion,
            [name]: value.trim()
        })
    }
    const getQuizQuestion = async () => {
        let data = {
            quiz_id: selectedQuiz && selectedQuiz._id
        }
        let result = await api.get(config.getQuizQuestion, true, data)
        if (result && result.code == 200) {
            setGetAllQuizQuestion(result.data)
        } else {
            console.log(result && result.message)
        }
    }
    const addingOption = () => {
        if (!quizQuestion.option) {
            notify("Please enter option")
            return
        }
        console.log("inside adding oPtion")
        console.log(questionOptions)
        setQuestionOptions([...questionOptions,
        { "option": quizQuestion.option }])
        setQuizQuestion({
            ...quizQuestion,
            option: ""
        })
    }
    const addQuizQuestionFun = async () => {
        if (questionOptions.length < 2) {
            notify("Please enter atleast two option")
            return
        }
        let data = quizQuestion
        data.options = JSON.stringify(questionOptions)
        data.quiz_id = selectedQuiz && selectedQuiz._id
        console.log("question data is", data)
        // return
        swal({
            html: true,
            title: "Thank You!,",
            text: `Are you sure to add quiz question`,
            icon: "warning",
            buttons: ["No, cancel it!", "Add Question"],
            dangerMode: true,
        }).then(async function (isConfirm) {
            if (isConfirm) {
                let result = await api.post(config.addQuizQuestion, data)
                if (result && result.code == 200) {
                    toast.success(`Hi, Thanks for adding new quiz question!`)
                    reset()
                    getQuizQuestion()
                } else {
                    toast.error(result && result.message)
                }
            }
        })
    }
    const reset = () => {
        setQuizQuestion({
            ...quizQuestion,
            // status: "",
            // type: "",
            // question_type: "",
            question: "",
            option: "",
            answer: "",
        })
        setQuestionOptions([])
    }
    const deleteOption = (option)=> {
        let indexOfOption = questionOptions.indexOf(option)
        console.log(indexOfOption)
        questionOptions.splice(questionOptions.indexOf(option), 1)
        setQuestionOptions([...questionOptions])
   }
    return (
        <div style={{ margin: "auto" }}>
            <div className="card">
                <div className="card-header">
                    <button type="button" onClick={() => setIsAddQuizQuestion(false)}
                        className="btn btn-success m-0 float-left"><i class="fa fa-arrow-left" aria-hidden="true"></i> Back</button>


                    <h4 className="text-center m-0 uppercase">Add Quiz question For : {selectedQuiz.quiz_name}</h4>

                </div>

                <div className="container">
                    <AvForm onValidSubmit={addQuizQuestionFun} className="row">

                        <div className="col-md-6 input-group custom mb-0">
                            <div className="col-lg-12 custom mb-0">
                                <span>Status</span>
                                <AvField type="select" className="form-control bg-transparent" name="status"
                                    value={quizQuestion.status} onChange={onChangeFun}

                                    validate={{
                                        required: {
                                            value: true,
                                            errorMessage: "Please Enter Quiz Type",
                                        },
                                    }}
                                    placeholder="Enter Quiz Type...">
                                    <option value="active">active</option>
                                    <option value="inactive">inactive</option>
                                </AvField>
                            </div>
                        </div>

                        <div className="col-md-6 input-group custom mb-0">
                            <div className="col-lg-12 custom mb-0">
                                <span>Type</span>
                                <AvField type="select" className="form-control bg-transparent" name="type"
                                    value={quizQuestion.type} onChange={onChangeFun}

                                    validate={{
                                        required: {
                                            value: true,
                                            errorMessage: "Please Enter Quiz Type",
                                        },
                                    }}
                                    placeholder="Enter Quiz Type...">
                                    <option hidden>Select Quiz Type</option>
                                    {Type && Type.length > 0 && Type.map((type) => (
                                        <option value={type.type}>{type.tag}</option>
                                    ))}
                                </AvField>
                            </div>
                        </div>
                        <div className="col-md-12 input-group custom mb-0">
                            <div className="col-lg-12 custom mb-0">
                                <span>Question Type</span>
                                <AvField type="select" className="form-control bg-transparent" name="question_type"
                                    value={quizQuestion.question_type} onChange={onChangeFun}

                                    validate={{
                                        required: {
                                            value: true,
                                            errorMessage: "Please Enter Quiz Type",
                                        },
                                    }}
                                    placeholder="Enter Quiz Question Type...">
                                    <option disabled>Select Quiz Type</option>
                                    <option value="checkbox">Checkbox</option>
                                    <option value="radio">Radio</option>
                                    <option value="text">Text</option>
                                    <option value="number">Number</option>
                                </AvField>
                            </div>
                        </div>
                        <div className="col-md-12 input-group custom mb-0">
                            <div className="col-lg-12 custom mb-0">
                                <span>Quiz Question</span>
                                <AvField className="form-control bg-transparent" name="question"
                                    value={quizQuestion.question} onChange={onChangeFun}

                                    validate={{
                                        required: {
                                            value: true,
                                            errorMessage: "Please Enter Quiz question",
                                        },
                                    }}
                                    placeholder="Enter Meta Tag...">
                                </AvField>
                            </div>
                        </div>

                        <div className="col-md-6 input-group custom mb-0">
                            <div className="col-lg-12 custom mb-0">
                                <div className="card">
                                    <div className="card-header">
                                        <span>Add Option</span>
                                    </div>
                                    <div className="card-body">
                                        <AvField className="form-control bg-transparent" name="option"
                                            value={quizQuestion.option} onChange={onChangeFun}
                                            validate={{
                                                required: {
                                                    value: false,
                                                    errorMessage: "Please Enter Quiz Option",
                                                },
                                            }}
                                            placeholder="Enter Option...">
                                        </AvField>
                                        <button type="button" onClick={addingOption} className="btn btn-success rounded-0 button-room" >
                                            <i className="fa fa-plus" aria-hidden="true" /> Add Option</button>
                                        {/* <div className="card-footer"> */}
                                        {/* </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 input-group custom mb-0">
                            <div className="col-lg-12 custom mb-0">
                                <div className="card" style={{ minHeight: "175px" }}>
                                    <div className="card-header">
                                        <span>Added Option</span>
                                    </div>
                                    <div className="card-body">

                                        <ul class="list-group">
                                            {questionOptions && questionOptions.length > 0 && questionOptions.map((option, index) => (
                                                <li className="list-group-item p-0">{index + 1}. {option.option}
                                                    {/* <button type="button" className="btn btn-info float-right" >
                                                        <span className="fa fa-edit" aria-hidden="true" /> </button> */}
                                                    <button onClick={()=>deleteOption(option)} type="button" className="btn float-right" >
                                                        <span className="fa fa-trash" /> </button>
                                                </li>
                                            ))}
                                            {questionOptions && questionOptions.length == 0 &&
                                                <h4 className="text-center">Option not added yet</h4>}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 input-group custom mb-0">
                            <div className="col-lg-12 custom mb-0">
                                <span>Answer</span>
                                <AvInput type="select" required className="form-control bg-transparent" name="answer"
                                    value={quizQuestion.answer} onChange={onChangeFun}

                                    validate={{
                                        required: {
                                            value: true,
                                            errorMessage: "Please Enter Quiz Answer",
                                        },
                                    }}
                                    placeholder="Enter Answer...">
                                    <option value="">Select Answer</option>
                                    {questionOptions && questionOptions.length > 0 &&
                                        questionOptions.map((op, index) => (
                                            <option value={op.option}>{index + 1} {op.option}</option>
                                        ))}
                                </AvInput>
                            </div>
                        </div>
                        <div className="col-lg-12 text-center text-center mt-3">
                            <button type="submit" className="btn btn-success btn-block rounded-0 button-room" >
                                <i className="fa fa-plus" aria-hidden="true" /> Add Question</button>
                        </div>
                    </AvForm>
                </div>
            </div>
            <div className="card">
                <div className="card-header">
                    <h4 className="text-center m-0 uppercase">Quiz question For : {selectedQuiz.quiz_name}</h4>
                </div>
                <div className="card-body">
                    <div className="container">
                        {getAllQuizQuestion && getAllQuizQuestion.length > 0 && getAllQuizQuestion.map((que, index) => (

                            <div className="card">
                                <div className="card-header">
                                    <h4 className="m-0">Question {index + 1}. {que.question} </h4>
                                </div>
                                <div className="card-body">
                                    <ul className="list-group">
                                        {que.options.length > 0 && que.options.map((op, ind) => (
                                            <li className="list-group-item">Option {ind + 1}. {op.option}</li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="card-footer">
                                    Answer : {que.answer}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {/* <ToastContainer /> */}

        </div>
    );
}

export default AddQuizQuestion;