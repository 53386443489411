
import React, { useState, useEffect } from 'react';
import { Editor, EditorTools } from '@progress/kendo-react-editor';
const {
    Bold,
    Italic,
    Underline,
    Strikethrough,
    Subscript,
    Superscript,
    AlignLeft,
    AlignCenter,
    AlignRight,
    AlignJustify,
    Indent,
    Outdent,
    OrderedList,
    UnorderedList,
    Undo,
    Redo,
    FontSize,
    FontName,
    FormatBlock,
    Link,
    Unlink,
    InsertImage,
    ViewHtml,
    InsertTable,
    AddRowBefore,
    AddRowAfter,
    AddColumnBefore,
    AddColumnAfter,
    DeleteRow,
    DeleteColumn,
    DeleteTable,
    MergeCells,
    SplitCell,
} = EditorTools;
const KendoTextEditor = (props) => {
    const { content, setContent } = props

    useEffect(() => {

    }, [])

    return (
        <div>
            <Editor tools={[[Bold, Italic, Underline, Strikethrough],
            [Subscript, Superscript],
            [AlignLeft, AlignCenter, AlignRight, AlignJustify],
            [Indent, Outdent], [OrderedList, UnorderedList],
                FontSize, FontName, FormatBlock,
            [Undo, Redo],
            [Link, Unlink, InsertImage, ViewHtml],
            [InsertTable],
            [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter],
            [DeleteRow, DeleteColumn, DeleteTable],
            [MergeCells, SplitCell]]} contentStyle={{
                minHeight: 200
            }} defaultContent={content}
                value={content}
                onChange={(e) => setContent(e.html)}

            />;

        </div>
    );
}

export default KendoTextEditor;