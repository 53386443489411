
import React, { useState, useEffect } from 'react';
import {
    CNav,
    CNavItem,
    CNavLink,
    CRow,
    CTabContent,
    CTabPane,
    CModal,
    CTabs,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CBadge,
    CDataTable,
    CButton,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCol,
    CForm,
    CSelect,
    CFormGroup,
    CInputGroupAppend,
    CInputGroup,
    CLabel,
    CInputCheckbox,
    CSwitch,
    CFormText,
    CInput,
    CInputFile,
} from '@coreui/react'
import API from 'utils/apiCalling';
import { config } from 'utils/apiUrl';
import swal from "sweetalert";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddQuiz from './addQuiz'
import GetQuiz from './getQuiz';
const QuizManagement = (props) => {
    const { } = props
    const api = new API()
    const [active, setActive] = useState(0)
    const [allQuiz, setAllQuiz] = useState()
    const [modal, setModal] = useState(false)
    const [selectedUser, setSelectedUser] = useState()
    const [isAddQuiz, setIsAddQuiz] = useState(false)

    useEffect(() => {
        getAllQuiz()
    }, [])
    const notify = (message) => toast(message);
    const getAllQuiz = async () => {
        let result = await api.get(config.getQuiz)
        console.log("getting quiz", result)
        if (result && result.code == 200) {
            console.log(result)
            setAllQuiz(result.data)
        } else {
            console.log(result && result.message)
        }
    }

    const deleteUser = async (user) => {
        swal({
            html: true,
            title: "Thank You!,",
            text: `Are you sure to delete User`,
            icon: "success",
            buttons: ["No, cancel it!", "Delete"],
            dangerMode: true,
        }).then(async function (isConfirm) {
            if (isConfirm) {
                let data = {
                    id: user._id
                }
                console.log(data)
                let result = await api.post(config.deleteUser, data)
                if (result && result.code == 200) {
                    toast.success("deleted")
                    getUser()
                } else {
                    notify(result && result.message)
                }
            }
        })
    }
    const statusActiveInactive = (user) => {
        let status = user.status == "active" ? "inactive" : "active";
        let data = {
            id: user._id,
            status : status
        }
        console.log(data)
        swal({
            html: true,
            title: "Thank You!,",
            text: `Are you sure to ${status} user`,
            icon: "success",
            buttons: ["No, cancel it!", "Submit"],
            dangerMode: true,
        }).then(async function (isConfirm) {
            if (isConfirm) {
                let result = await api.post(config.userStatusActiveInactive, data)
                if (result && result.code == 200) {
                    console.log("user status", result)
                    toast.success(result.message)
                    getUser()
                }
                else {
                    toast.success(result && result.code == 200)
                }
            }
        }
        )
    }
    const toggle = (user) => {
        setSelectedUser(user)
        setModal(!modal)
    }
    return (
        <div>
            <CRow>
                <CCol xs="12" >
                    <CCard>
                        <CCardHeader>
                            Quiz MANAGEMENT
                            <button className="btn btn-success mr-2 float-right" onClick={(e)=>setIsAddQuiz(!isAddQuiz)}>Add New Quiz</button>
                        </CCardHeader>
                        <CCardBody>
                            <CTabs activeTab={active} onActiveTabChange={idx => setActive(idx)}>
                                <CNav variant="tabs">
                                    <CNavItem>
                                        <CNavLink>
                                        Add New Quiz
                                        </CNavLink>
                                    </CNavItem>
                                </CNav>
                                <CTabContent>
                                    <CTabPane>
                                     <AddQuiz isAddQuiz={isAddQuiz}
                                     setIsAddQuiz={setIsAddQuiz} getAllQuiz={getAllQuiz}/>
                                     <GetQuiz allQuiz={allQuiz}/>
                                    </CTabPane>
                                </CTabContent>
                            </CTabs>
                            <CCardFooter>
                                {/* <CButton color="success" >Back</CButton>{' '} */}
                            </CCardFooter>

                        </CCardBody>
                    </CCard>

                </CCol>
            </CRow>

            <ToastContainer />
        </div >
    );
}

export default QuizManagement;