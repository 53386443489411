
import React, { useState, useEffect } from 'react';
import GetBlogSection from './getBlogSection';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import API from '../../utils/apiCalling'
import { config } from '../../utils/apiUrl'
import swal from "sweetalert";
// import { Editor, EditorTools } from '@progress/kendo-react-editor';
import { ToastContainer, toast } from 'react-toastify'
import KendoTextEditor from 'views/common/kendo_text_editor';
import {
    CCollapse,
} from '@coreui/react'
let blogType = [
    { blog_type: "medical", blog_tag: "Medical blog" },
    { blog_type: "gk", blog_tag: "Gk blog" },
]


const AddBlogSection = (props) => {
    const { } = props
    const api = new API()
    const [blogMainImage, setBlogMainImage] = useState()
    const [blogSubImage, setBlogSubImage] = useState()
    const [blogAddedTrigger, setBlogAddedTrigger] = useState(false)
    const [content, setContent] = useState("gkknow.com")
    const [addBlogActive, setAddBlogActive] = useState(false)
    const [blog, setBlog] = useState({
        blog_heading: "",
        blog_sub_heading: "",
        blog_content: "",
        blog_sub_content_1: "",
        blog_sub_content_2: "",
        blog_tag: "",
        blog_type: "",
        blog_title : "",
        meta_tag: "",
        meta_description:"",
        meta_title:"",
        status: "inactive",
        // blog_main_image: "",
        // blog_sub_image: "",
    })

    useEffect(() => {
        console.log(">>>>", content)
    }, [content])

    const onChangeFun = (e) => {
        console.log(e.target.files)
        if (e.target.name == "blog_main_image" || e.target.name == "blog_sub_image") {
            setBlog({
                ...blog,
                [e.target.name]: e.target.files[0]
            })
        } else {
            const { name, value } = e.target
            setBlog({
                ...blog,
                [name]: value
            })
        }
        console.log("inside else", blog)
    }
    const addBlog = (e) => {
        const formData = new FormData();
        formData.append("blog_heading", blog.blog_heading)
        formData.append("blog_url", blog.blog_url)
        formData.append("blog_sub_heading", blog.blog_sub_heading)
        formData.append("blog_content", content)
        // formData.append("blog_sub_content_1", blog.blog_sub_content_1)
        // formData.append("blog_sub_content_2", blog.blog_sub_content_2)
        formData.append("blog_tag", blog.blog_tag)
        formData.append("blog_type", blog.blog_type)
        formData.append("meta_description", blog.meta_description)
        formData.append("meta_tag", blog.meta_tag)
        formData.append("meta_title", blog.meta_title)
        formData.append('blog_createdBy', "GkKnow");
        formData.append('blog_createdDate', "11-09-2021");
        formData.append('blog_createdTime', "13:00")
        formData.append("files", blogMainImage, blogMainImage.name)
        formData.append("files", blogSubImage, blogSubImage.name)
        formData.append("status", blog.status)

        console.log("form data is", formData)
        swal({
            html: true,
            title: "Thank You!,",
            text: `Are you sure to add blog`,
            icon: "success",
            buttons: ["No, cancel it!", "Submit"],
            dangerMode: true,
        }).then(async function (isConfirm) {
            if (isConfirm) {

                let result = await api.postUpload(config.addBlog, formData)
                if (result) {
                    console.log("blog is", result)
                    toast.success(`Hi, Thanks for add blog!`)
                    // reset()
                    setBlogAddedTrigger(!blogAddedTrigger)
                }
                else {
                    toast.error(`Hi, Something went wrong!`)
                }
            }
        }
        )
    }
    const reset = () => {
        setBlog({
            blog_heading: "",
            blog_sub_heading: "",
            blog_content: "",
            blog_sub_content_1: "",
            blog_sub_content_2: "",
            blog_tag: "",
            blog_type: "",
            meta_tag: "",
            meta_description: "",
            meta_title: "",
            inactive: "inactive"
        })
    }
    return (
        <div >
            <div className="card">
                <div className="card-header">
                    <div className="card-heading">
                        Blog Section
                        <button onClick={() => setAddBlogActive(!addBlogActive)} type="submit" className="btn btn-info float-right">{addBlogActive ? <i class="fa fa-arrow-up" aria-hidden="true"></i> : <i class="fa fa-arrow-down" aria-hidden="true"></i>}Add Blow</button>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row" style={{ marginTop: "1%" }}>
                        <CCollapse show={addBlogActive}>
                            <div className="col-md-12">
                                <div className="container" style={{ width: "90%", border: "1px solid" }}>
                                    <div className="col-lg-12">
                                        <h4 className="text-center mb-0 uppercase">ADD NEW BLOG</h4>
                                    </div>
                                    <AvForm onValidSubmit={addBlog} className="row">
                                        <div className="col-md-6 input-group custom mb-0">
                                            <div className="col-lg-12 custom mb-0">
                                                <span>Status</span>
                                                <AvField type="select" className="form-control bg-transparent" name="status"
                                                    value={blog.status} onChange={onChangeFun}

                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage: "Please Enter blog Type",
                                                        },
                                                    }}
                                                    placeholder="Enter blog Type...">
                                                    <option value="active">active</option>
                                                    <option value="inactive">inactive</option>

                                                </AvField>
                                            </div>
                                        </div>
                                        <div className="col-md-6 input-group custom mb-0">
                                            <div className="col-lg-12 custom mb-0">
                                                <span>Type</span>
                                                <AvField type="select" className="form-control bg-transparent" name="blog_type"
                                                    value={blog.blog_type} onChange={onChangeFun}

                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage: "Please Enter blog Type",
                                                        },
                                                    }}
                                                    placeholder="Enter blog Type...">
                                                    <option hidden>Select Blog Type</option>
                                                    {blogType && blogType.length > 0 && blogType.map((type) => (
                                                        <option value={type.blog_type}>{type.blog_tag}</option>
                                                    ))}
                                                </AvField>
                                            </div>
                                        </div>
                                        <div className="col-md-6 input-group custom mb-0">
                                            <div className="col-lg-12 custom mb-0">
                                                <span>Meta Tag</span>
                                                <AvField className="form-control bg-transparent" name="meta_tag"
                                                    value={blog.meta_tag} onChange={onChangeFun}

                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage: "Please Enter Blog Meta Tag",
                                                        },
                                                    }}
                                                    placeholder="Enter Meta Tag...">
                                                </AvField>
                                            </div>
                                        </div>
                                        <div className="col-md-6 input-group custom mb-0">
                                            <div className="col-lg-12 custom mb-0">
                                                <span>Meta Description</span>
                                                <AvField className="form-control bg-transparent" name="meta_description"
                                                    value={blog.meta_description} onChange={onChangeFun}

                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage: "Please Enter Blog Meta Description",
                                                        },
                                                    }}
                                                    placeholder="Enter Meta Description...">
                                                </AvField>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 input-group custom mb-0">
                                            <div className="col-lg-12 custom mb-0">
                                                <span>Blog Title</span>
                                                <AvField className="form-control bg-transparent" type="text" name="blog_title"
                                                    value={blog && blog.blog_title} onChange={onChangeFun}
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage: "Please Enter Blog Title",
                                                        },

                                                    }}
                                                    placeholder="Enter Blog Title..." />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 input-group custom mb-0">
                                            <div className="col-lg-12 custom mb-0">
                                                <span>Meta Title</span>
                                                <AvField className="form-control bg-transparent" type="text" name="meta_title"
                                                    value={blog && blog.meta_title} onChange={onChangeFun}
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage: "Please Enter Blog Title",
                                                        },

                                                    }}
                                                    placeholder="Enter Blog Title..." />
                                            </div>
                                        </div>
                                        <div className="col-md-12 input-group custom mb-0">
                                            <div className="col-lg-12 custom mb-0">
                                                <span>Blog URL</span>
                                                <AvInput className="form-control bg-transparent" type="text" name="blog_url"
                                                    value={blog.blog_url} onChange={onChangeFun}
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage: "Please Enter blog URL",
                                                        },
                                                    }}
                                                    placeholder="Enter blog URL..." />
                                            </div>
                                        </div>
                                        <div className="col-md-12 input-group custom mb-0">
                                            <div className="col-lg-12 custom mb-0">
                                                <span>Blog Heading</span>
                                                <AvInput className="form-control bg-transparent" type="text" name="blog_heading"
                                                    value={blog.blog_heading} onChange={onChangeFun}
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage: "Please Enter blog heading",
                                                        },
                                                    }}
                                                    placeholder="Enter blog heading..." />
                                            </div>
                                        </div>
                                        <div className="col-md-12 input-group custom mb-0">
                                            <div className="col-lg-12 custom mb-0">
                                                <span>Blog Sub Heading</span>
                                                <AvInput className="form-control bg-transparent" type="text" name="blog_sub_heading"
                                                    value={blog.blog_sub_heading} onChange={onChangeFun}
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage: "Please Enter",
                                                        },
                                                    }}
                                                    placeholder="Enter blog sub heading..." />
                                            </div>
                                        </div>
                                        <div className="col-md-12 input-group custom mb-0">
                                            <div className="col-lg-12 custom mb-0">
                                                <span>Blog Tag</span>
                                                <AvInput className="form-control bg-transparent" type="text" name="blog_tag"
                                                    value={blog.blog_tag} onChange={onChangeFun}
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage: "Please Enter",
                                                        },
                                                    }}
                                                    placeholder="Enter blog tag..." />
                                            </div>
                                        </div>
                                        <div className="col-md-12 input-group custom mb-0">
                                            <div className="col-lg-12 custom mb-0">
                                                <span>Blog Content</span>

                                                {/* <Editor tools={[[Bold, Italic, Underline, Strikethrough],
                                            [Subscript, Superscript],
                                            [AlignLeft, AlignCenter, AlignRight, AlignJustify],
                                            [Indent, Outdent], [OrderedList, UnorderedList],
                                                FontSize, FontName, FormatBlock,
                                            [Undo, Redo],
                                            [Link, Unlink, InsertImage, ViewHtml],
                                            [InsertTable],
                                            [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter],
                                            [DeleteRow, DeleteColumn, DeleteTable],
                                            [MergeCells, SplitCell]]} contentStyle={{
                                                minHeight: 200
                                            }} defaultContent={content}
                                                value={content}
                                                onChange={(e) => setContent(e.html)}
                                                
                                            />; */}
                                                <KendoTextEditor content={content} setContent={setContent} />
                                            </div>
                                        </div>


                                        <div className="col-md-6 input-group custom mb-0">
                                            <div className="col-lg-12 custom mb-0">
                                                <span>Blog Main Image</span>
                                                <AvInput className="form-control bg-transparent" type="file" name="blog_main_image"
                                                    value={blog.blog_main_image} onChange={(e) => setBlogMainImage(e.target.files[0])}
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage: "Please Enter",
                                                        },
                                                    }}
                                                    placeholder="Enter blog main image..." />
                                            </div>
                                        </div>
                                        <div className="col-md-6 input-group custom mb-0">
                                            <div className="col-lg-12 custom mb-0">
                                                <span>Blog Sub Image</span>
                                                <AvInput className="form-control bg-transparent" type="file" name="blog_sub_image"
                                                    value={blog.blog_sub_image} onChange={(e) => setBlogSubImage(e.target.files[0])}
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage: "Please Enter",
                                                        },
                                                    }}
                                                    placeholder="Enter blog sub image..." />
                                            </div>
                                        </div>
                                        {/* <Editor tools={[[Bold, Italic, Underline, Strikethrough],
                                    [Subscript, Superscript],
                                    [AlignLeft, AlignCenter, AlignRight, AlignJustify],
                                    [Indent, Outdent], [OrderedList, UnorderedList],
                                        FontSize, FontName, FormatBlock,
                                    [Undo, Redo],
                                    [Link, Unlink, InsertImage, ViewHtml],
                                    [InsertTable],
                                    [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter],
                                    [DeleteRow, DeleteColumn, DeleteTable],
                                    [MergeCells, SplitCell]]} contentStyle={{
                                        minHeight: 200
                                    }} defaultContent={content}
                                        value={content}
                                        onChange={(e) => setContent(e.html)}
                                    />; */}
                                        {/* <div style={{ height: "200px", overflow: "auto", display: "block" }}>
                                        <div contentEditable='true' dangerouslySetInnerHTML={{ __html: content }}></div>
                                    </div> */}



                                        <div className="col-lg-12 text-center text-center mt-3">
                                            <button type="submit" className="btn btn-success btn-block rounded-0 button-room" >
                                                <i className="fa fa-plus" aria-hidden="true" /> Add Blog </button>
                                        </div>
                                    </AvForm>
                                    <div>
                                    </div>
                                </div>
                            </div>
                        </CCollapse>
                        <div className="col-md-12">
                            <GetBlogSection blogAddedTrigger={blogAddedTrigger} />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default AddBlogSection;