
import React, { useState, useEffect } from 'react';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import API from '../../../utils/apiCalling'
import { config } from '../../../utils/apiUrl'
import { validation } from '../../../utils/configCommon';
import { setLocalDataAsObject, getLocalDataAsObject } from '../../../utils/CoustomStorage';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import swal from "sweetalert";
import GkTab from '../tab';
import AddMultipleChoiceQue from './add_gk';

const MultipleChoiceQuestionSection = (props) => {
    const { } = props
    let api = new API()
    let history = useHistory();
    const [user, setUser] = useState({
        email: "",
        password: ""
    })

    useEffect(() => {

    }, [])
    const notify = (message) => toast(message);

    const onChangeFun = (e) => {
        const { name, value } = e.target
        // console.log(name, value)
        setUser({
            ...user,
            [name]: value
        })
    }
    const AddQuestion = async () => {
        swal({
            html: true,
            title: "Thank You!,",
            text: `Are you sure to login`,
            icon: "success",
            buttons: ["No, cancel it!", "Login"],
            dangerMode: true,
        }).then(async function (isConfirm) {
            if (isConfirm) {
                let data = {

                }
                let result = await api.post(config.login, data)
                console.log("Adding question", result)
                if (result && result.token) {
                    notify(`Hi, Thanks for add question!`)
                    // history.push('/admin/dashboard')
                } else {
                    notify(`Hi, Unable to add question!`)
                }
            }
        }
        )

    }
    return (
        <div>
            <GkTab />
            <div className="container-fluid">
                <AddMultipleChoiceQue />
            </div>
            <ToastContainer />
        </div>
    );
}

export default MultipleChoiceQuestionSection;